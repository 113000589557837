import moment from 'moment'
import SettingsService from '../../services/setting.service'
import SOCKET_EVENT_TYPES from '../../functions/socket-event.type'

export default{
  namespaced: true,
  state:{
    activeUserSettings: {},
  },
  getters: {
    selectUserSettings: (state) => {
      const settings = state.activeUserSettings
      const application = {}
      const hours = moment().format('HH')

      switch(settings.theme) {
        case 'dark':
          application.bg = {
            "color": "white",
            "backgroundColor": settings.backgroundDark,
            "--color": "white",
            "--background": settings.backgroundDark
          },
          application.fg = {
            "--color": 'white',
            "color": 'white',
            "--background": settings.foregroundDark,
            "backgroundColor": settings.foregroundDark,
            "border": `5px solid ${settings.foregroundDark}`,
            li: {
              "--color": 'white',
              "color": 'white',
              "--background": settings.foregroundDark,
              "backgroundColor": settings.foregroundDark,
            }
          }
          break

        case 'light':
          application.bg = {
            "color": "black",
            "backgroundColor": settings.backgroundLight,
            "--color": "black",
            "--background": settings.backgroundLight
          },
          application.fg = {
            "--color": 'black',
            "color": 'black',
            "--background": settings.foregroundLight,
            "backgroundColor": settings.foregroundLight,
            "border": `5px solid ${settings.foregroundLight}`,
            li: {
              "--color": 'black',
              "color": 'black',
              "--background": settings.foregroundLight,
              "backgroundColor": settings.foregroundLight,
            }
          }
          break

        case 'auto':
          // in the evening around 7pm
          if(hours >= 19) {
            // application.bg = { 
            //   "backgroundColor": settings.backgroundDark,
            //   "--background": settings.backgroundDark
            // },
            // application.fg = {
            //   "--color": 'white',
            //   "color": 'white',
            //   "--background": settings.foregroundDark,
            //   "backgroundColor": settings.foregroundDark,
            //   "border": `5px  solid ${settings.foregroundDark}`,
            // }
            application.bg = {
              "color": "white",
              "backgroundColor": settings.backgroundDark,
              "--color": "white",
              "--background": settings.backgroundDark
            },
            application.fg = {
              "--color": 'white',
              "color": 'white',
              "--background": settings.foregroundDark,
              "backgroundColor": settings.foregroundDark,
              "border": `5px solid ${settings.foregroundDark}`,
              li: {
                "--color": 'white',
                "color": 'white',
                "--background": settings.foregroundDark,
                "backgroundColor": settings.foregroundDark,
              }
            }
          }
          else {
            application.bg = {
              "color": "black",
              "backgroundColor": settings.backgroundLight,
              "--color": "black",
              "--background": settings.backgroundLight
            },
            application.fg = {
              "--color": 'black',
              "color": 'black',
              "--background": settings.foregroundLight,
              "backgroundColor": settings.foregroundLight,
              "border": `5px solid ${settings.foregroundLight}`,
              li: {
                "--color": 'black',
                "color": 'black',
                "--background": settings.foregroundLight,
                "backgroundColor": settings.foregroundLight,
              }
            }
          }
          break
        default:
          console.log('Unknown operations')
      }

      return application
    },
    selectApplicationSettings(state){
      return state.activeUserSettings
    },
  },
  mutations: {
    SET_ACTIVE_USER_SETTINGS(state, setting) {
      state.activeUserSettings = setting
    }
  },
  actions: {
    async saveDefaultColorPalette({ commit, getters ,rootGetters }, palette){
      const { _id: userId } = rootGetters['user/selectCurrentUser']
      const userSettings = getters['selectApplicationSettings']
      const payload = {
        userId,
        fields: {
          chart: {
            ...userSettings.chart,
            palette: palette
          }
        }
      }
      const response = await SettingsService.update(payload)
      if(response && response.data){
        commit('SET_ACTIVE_USER_SETTINGS', response.data)
      }
    },

    async saveFetchIntervalSettings({ commit, getters ,rootGetters, dispatch }, interval){
      const { _id: userId } = rootGetters['user/selectCurrentUser']
      const userSettings = getters['selectApplicationSettings']
      const payload = {
        userId,
        fields: {
          chart: {
            ...userSettings.chart,
            fetchInterval: interval
          }
        }
      }

      const response = await SettingsService.update(payload)
      if(response && response.data){
        commit('SET_ACTIVE_USER_SETTINGS', response.data)

        // send the event to server socket
        await dispatch('websocket/emitEvent', {
          event: 'CLIENT_ROUTE_EVENT',
          payload: {
            event: SOCKET_EVENT_TYPES.UPDATE_CHART_SETTINGS,
            data: {
              user: userId
            }
          }
        }, { root: true })
      }
    },

    async saveContinuousFetchingSettings({ commit, getters, rootGetters, dispatch }, setting){
      const { _id: userId } = rootGetters['user/selectCurrentUser']
      const userSettings = getters['selectApplicationSettings']
      const payload = {
        userId,
        fields: {
          chart: {
            ...userSettings.chart,
            fetch: !userSettings.chart.fetch
          }
        }
      }

      const response = await SettingsService.update(payload)
      if(response && response.data){
        commit('SET_ACTIVE_USER_SETTINGS', response.data)

        // send the event to server socket
        await dispatch('websocket/emitEvent', {
          event: 'CLIENT_ROUTE_EVENT',
          payload: {
            event: SOCKET_EVENT_TYPES.UPDATE_CHART_SETTINGS,
            data: {
              user: userId
            }
          }
        }, { root: true })
      }
    },

    async saveDarkModeSettings({ rootGetters, commit }, theme ){
      const { _id: userId } = rootGetters['user/selectCurrentUser']

      const payload = {
        userId,
        fields: {
          theme
        }
      }

      const response = await SettingsService.update(payload)

      if(response && response.data){
        commit('SET_ACTIVE_USER_SETTINGS', response.data)
      }
    },

    async fetchActiveUserSettings({ commit, rootGetters }){
      const { _id: userId } = rootGetters['user/selectCurrentUser']
      
      const payload = {
        filters: {
          userIds: [ userId ],
        }
      }

      const response = await SettingsService.list(payload)

      if(response && response.data){
        commit('SET_ACTIVE_USER_SETTINGS', response.data)
      }
    },

    async saveMenuPositionSettings({ commit, rootGetters }, position ){
      const { _id: userId } = rootGetters['user/selectCurrentUser']

      const payload = {
        userId,
        fields: {
          menuPosition: position
        }
      }

      const response = await SettingsService.update(payload)

      if(response && response.data){
        commit('SET_ACTIVE_USER_SETTINGS', response.data)
      }
    }
  }
}