<template>
  <div class="scroll" style="min-height: 700px">
    <ul class="list-group">
      <li v-for="(timeline, timestamp) in chatTimeline" :key="timestamp">
        <div class="d-flex justify-content-center align-items-center mb-4 mt-3">
          <span class="text-muted">{{ timestamp }}</span>
        </div>
        <ul class="list-group px-2" v-if="!isLoading">
          <li v-for="chat in timeline.chat" :key="chat._id">
            <ChatItem :chat="chat" :agent="agent" :avatar="avatar" />
          </li>
          <li class="d-flex justify-content-center align-items-center flex-column">
            <div v-if="showAllCaughtUp" class="py-4">
              <span>
                🎉 You're All Caught up! 🎉
              </span>
            </div>
            <div v-if="isLaodingMore">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </li>
        </ul>
      </li>
    </ul>

    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center mt-5">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import Toaster from "../../../../../lib/toaster";
import ChatItem from "./ChatItem.vue";
import { createNamespacedHelpers } from "vuex";

const AgentModule = createNamespacedHelpers("agent");
const AvatarModule = createNamespacedHelpers("avatar");

export default {
  name: "ChatList",
  components: {
    ChatItem,
  },
  data() {
    return {
      timeoutId: null,
      isLoading: false,
      showAllCaughtUp: false,
    };
  },
  computed: {
    ...AgentModule.mapGetters([
      "selectAgent",
      "selectAllLoaded",
      "selectChatTimeline",
      "selectAgentResponses",
      "selectIsLoadingMore",
    ]),
    ...AvatarModule.mapGetters(["avatar"]),

    agent() {
      return this.selectAgent;
    },
    responses() {
      return this.selectAgentResponses;
    },
    allLoaded() {
      return this.selectAllLoaded;
    },
    chatTimeline() {
      return this.selectChatTimeline;
    },
    isLaodingMore() {
      return this.selectIsLoadingMore;
    },
  },
  watch: {
    allLoaded: debounce(function(value) {
      if (value) {
        this.showAllCaughtUp = true;
        this.timeoutId = setTimeout(this.hideAllCaughtUp, 3000);
      }
    }),
  },
  methods: {
    ...AgentModule.mapActions([
      "fetchAgent",
      "fetchAgentResponses",
      "setAllLoaded",
    ]),

    async loadAgentAndResponses() {
      this.isLoading = true;
      try {
        await this.fetchAgent();
        await this.fetchAgentResponses({ limit: 10 });
      } catch (err) {
        Toaster.error({
          title: "Error",
          message: err.message,
          position: "top",
        });
      }
      this.isLoading = false;
    },
    hideAllCaughtUp() {
      console.log("hideAllLoaded");
      this.showAllCaughtUp = false;
    },
  },
  mounted() {
    this.loadAgentAndResponses();
  },
  unmounted() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
};
</script>

<style lang="scss" scoped></style>
