import MessageService from '../../services/message.service'

export default {
  namespaced: true,

  state: {
    messages: [],
    currentConvoMessages: [],
  },
  getters: {
    selectMessages : (state) => {
      return state.messages
    },
    selectConvoMessages(state) {
      return state.currentConvoMessages
    }
  },
  mutations: {
    SET_MESSAGES: (state, messages) => {
      state.messages = messages
    },
    SET_SELECTED_CONVO_MESSAGES(state, messages) {
      state.currentConvoMessages = messages
    }
  },

  actions: {
    async reply({ rootState }, message) {
      let result
      
      try {
        const conversation = rootState.conversation.currentConversation
        const restrictedNumber = '+14156884656'
        const { from } = conversation.parties
        const { phone } = conversation.parties.person

        const payload = {
          message: {
            to: phone === restrictedNumber ? from : phone,
            body: message
          }
        }
        result = await MessageService.send(payload)
      }
      catch (err) {
        result = err
      }

      return result
    },
    async fetchMessages({ commit }, payload) {
      try{
        const response = await MessageService.list(payload)
        commit('SET_MESSAGES', response.data)
      }
      catch (err) {
        console.log(err)
      }
    },
    async fetchSelectedConvoMessages({ commit }, { conversationId }){
      const payload = {
        filters: {
          conversationIds: [conversationId]
        }
      }

      const response = await MessageService.list(payload)

      if(response && response.data){
        commit('SET_SELECTED_CONVO_MESSAGES', response.data )
      }
    },
  }
}