import axios from 'axios'

export default {
  list(payload){
    return axios.post('/setting/list', { 
      ...payload 
    })
  },
  update(payload){
    return axios.put('/setting/update', {
      ...payload 
    })
  },
  loadDefault(payload){
    return axios.delete('/setting/default',{ 
      ...payload 
    })
  }
}