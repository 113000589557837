<template>
  <div class="loader-wrapper">
    <div class="flex-container">
    <div class="rows">
      <div class="row">
        <div class="d-flex justify-content-center align-items-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div> 
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'StatusLoader',
}
</script>

<style scoped lang="scss">
  .loader-wrapper {
    height: 100%;
    margin-top: 250px;
  }

  .flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .rows {
    display: flex;
    flex-direction: column;
  }
</style>
