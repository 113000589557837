// Written By : Nemese Kalubi
// Date: Fri, Nov 4, 2022

import axios from 'axios'

/**
 * @module agent.service
 * this file contains the agent's feature
 */

export default {
  getAgent(payload){
    return axios.get('/webhooks/ai/agent/')
  },
  
  getResponses(payload) {
    return axios.post('/webhooks/ai/agent/responses', {
      ...payload
    })
  }
}