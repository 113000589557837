import UserService from "../../services/user.service"

/**
 * @module password-recovery
 */

export default {
  namespaced: true,
  state: {
    currentStep: 'validate-username',
    emailError: null,
    recoveryUser: {},
    httpResponse: {},
    currentLookupStep: 'account-lookup',
  },
  getters: {
    selectCurrentStep(state){
      return state.currentStep
    },
    selectRecoveryUser(state){
      return state.recoveryUser
    },
    selectEmailError(state){
      return state.emailError
    },
    selectHttpResponse(state){
      return state.httpResponse
    },
    selectcurrentLookupStep(state){
      return state.currentLookupStep
    },
  },
  mutations: {
    SET_CURRENT_STEP(state, step){
      state.currentStep = step
    },
    SET_RECOVERY_USER(state, user){
      state.recoveryUser = user
    },
    SET_HTTP_RESPONSE(state, response){
      state.httpResponse = response
    },
    SET_EMAIL_ERROR_MESSAGE(state, errorMessage){
      state.emailError = errorMessage
    },
    SET_CURRENT_LOOKUP_STEP(state, step){
      state.currentLookupStep = step
    }
  },
  actions: {
    setCurrentStep({ commit }, step) {
      commit('SET_CURRENT_STEP', step)
    },
    setCurrentLookupStep({ commit }, step) {
      commit('SET_CURRENT_LOOKUP_STEP', step)
    },
    setEmailErrorMessage({ commit }, message) {
      commit('SET_EMAIL_ERROR_MESSAGE', message)
    },
    async findRecoveryUsername({ commit }, username) {
      const response = await UserService.get(username)

      if(response && response.data){
        commit('SET_RECOVERY_USER', response.data)
        commit('SET_HTTP_RESPONSE', null)
      }
      else {
        commit('SET_RECOVERY_USER', null)
        commit('SET_HTTP_RESPONSE', response)
      }
    }
  }
}