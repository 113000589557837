// import LoginBase from '../components/modules/auth/LoginBase.vue'
// import Profile from '../components/modules/profile/Profile.vue'
// import Account from '../components/modules/settings/account-settings/AccountSettings.vue'
// import System from '../components/modules/settings/system-settings/SystemSettings.vue'
// import Message from '../components/modules/message/Message.vue'
// import NotFound from '../components/404.vue'
// import Dashboard from '../components/modules/dashboard/Dashboard.vue'
// import DashboardPageV2 from '../components/pages/DashboardPage.vue'
// import Hyacinth from '../components/modules/hyacinth/Hyacinth.vue'\
// import ActivitiesPage from '../components/pages/ActivitiesPage.vue'
// import HyacinthV2Page from '../components/pages/HyacinthV2Page.vue'
// import Notification from '../components/modules/notification/Notification.vue'
// import AccountLookupBase from '../components/modules/auth/account-lookup/AccountLookupBase.vue'
// import PasswordRecoveryBase from '../components/modules/auth/password-recovery/PasswordRecoveryBase.vue'

import AuthService from '../services/auth.service'
import { createRouter, createWebHistory } from '@ionic/vue-router'

const System = () => import(/* webpackChunkName: "system" */ '../components/modules/settings/system-settings/SystemSettings.vue')
const Account = () => import(/* webpackChunkName: "account" */ '../components/modules/settings/account-settings/AccountSettings.vue')
const Message = () => import(/* webpackChunkName: "message" */ '../components/modules/message/Message.vue')
const Profile = () => import(/* webpackChunkName: "profile" */ '../components/modules/profile/Profile.vue')
const NotFound = () => import(/* webpackChunkName: "notFound" */ '../components/404.vue')
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../components/modules/dashboard/Dashboard.vue')
const LoginBase = () => import(/* webpackChunkName: "loginBase" */ '../components/modules/auth/LoginBase.vue')
// const Notification = () => import(/* webpackChunkName: "notification" */ '../components/modules/notification/Notification.vue')
const DashboardPageV2 = () => import(/* webpackChunkName: "dashboardPageV2" */ './../components/pages/DashboardPage.vue')
const ActivitiesPage = () => import(/* webpackChunkName: "activitiesPage" */ '../components/pages/ActivitiesPage.vue')
const HyacinthV2Page = () => import(/* webpackChunkName: "hyacinthV2Page" */ '../components/pages/HyacinthV2Page.vue')
const AccountLookupBase = () => import(/* webpackChunkName: "accountLookupBase" */ '../components/modules/auth/account-lookup/AccountLookupBase.vue')
const PasswordRecoveryBase = () => import(/* webpackChunkName: "passwordRecoveryBase" */ '../components/modules/auth/password-recovery/PasswordRecoveryBase.vue')
const TermsAndConditions = () => import(/* webpackChunkName: "terms" */ './../components/pages/TermsAndConditions.vue')
const EULA = () => import(/* webpackChunkName: "eula" */ './../components/pages/EULA.vue')
const PrivacyPolicy = () => import(/* webpackChunkName: "PrivacyPolicy" */ './../components/pages/PrivacyPolicy.vue')

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    beforeEnter: (to, from, next) => checkAuthenticationStatus(to, from, next)
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPageV2,
    beforeEnter: (to, from, next) => checkAuthenticationStatus(to, from, next)
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginBase
  },
  {
    path: '/message',
    name: 'Message',
    component: Message,
    beforeEnter: (to, from, next) => checkAuthenticationStatus(to, from, next)
  },
  {
    path: '/hyacinth',
    name: 'Hyacinth',
    component: HyacinthV2Page,
    beforeEnter: (to, from, next) => checkAuthenticationStatus(to, from, next)
  },
  {
    path: '/activities',
    name: 'Activities',
    component: ActivitiesPage,
    beforeEnter: (to, from, next) => checkAuthenticationStatus(to, from, next)
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Dashboard,
    beforeEnter: (to, from, next) => checkAuthenticationStatus(to, from, next)
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: (to, from, next) => checkAuthenticationStatus(to, from, next)
  },
  {
    path: '/system',
    name: 'System',
    component: System,
    beforeEnter: (to, from, next) => checkAuthenticationStatus(to, from, next)
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: PasswordRecoveryBase,
  },
  {
    path: '/account-lookup',
    name: 'account-lookup',
    component: AccountLookupBase,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsAndConditions,
  },
  {
    path: '/eula',
    name: 'eula',
    component: EULA,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound,
  },
]

const checkAuthenticationStatus = async (to, from, next )=> {
  const isAuthenticated = await AuthService.handleAuthentication();
  if (to.name !== "Login" && !isAuthenticated) await next({ name: "Login" });
  else await next();
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
