// Written By : Nemese Kalubi
// Date: Fri Apr 15, 2022

import EventRouter from '../../functions/socket-events/index.js'
import WebsocketService from "../../services/socket.service"
import SOCKET_EVENT_TYPES from '../../functions/socket-event.type'

/**
 * @module websocket.module
 */

export default {
  namespaced: true,
  state: {
    connection: null,
    socket: null,
  },
  getters: {
    selectSocket(state) {
      return state.socket;
    },
  },
  mutations: {
    setSocket(state, socket) {
      state.socket = socket;
    },
  },
  actions: {
    async connectSocket({ commit, state}, options = {}) {
      if(state.socket && state.socket.connected){
        console.log('You are already connected')
      }
      else{
        try {
          const socket = await WebsocketService.connect()
          
          setTimeout(() => {
            if (socket && socket.connected) {
              console.log("*** websocket connected ***");
              commit("setSocket", socket);
            } else {
              console.log("Websocket Connection failed", { socket });
            }
          }, 1000);


          // server event listener method
          socket.on('SERVER_ROUTE_EVENT', async(context) => {
            const { event, data } = context
            // switch case on event type
            if(event === SOCKET_EVENT_TYPES.MESSAGE_SENT){
              await EventRouter.MessageSentEvent(data)
            }
            else if(event === SOCKET_EVENT_TYPES.FETCH_REPORTS){
              await EventRouter.FetchReportsEvent(data)
            }
            else if(event === SOCKET_EVENT_TYPES.NEW_MESSAGE_RECEIVED){
              await EventRouter.MessageReceivedEvent(data)
            }
            else if (event === SOCKET_EVENT_TYPES.FETCH_TEMPERATURE){
              await EventRouter.fetchTemperatureEvent(data)
            }
            else if (event === SOCKET_EVENT_TYPES.DISPLAY_ROUTE_INFO){
              await EventRouter.displayRouteInfoEvent(data)
            }
            else if (event === SOCKET_EVENT_TYPES.AGENT_DISPLAY_CHAT){
              await EventRouter.handleAgentResponseChatEvent(data)
            }
            else{
              console.log('Unknown socket event received.')
            }
          })
        } 
        catch (err) {
          console.log(err);
        }
      }
    },

    async disconnectSocket({ commit, state }) {
      try {
        const socket = state.socket;

        if (socket && socket.connected) {
          await socket.disconnect(); // disconnect from server
          commit("setSocket", socket);
        } else {
          console.log("Socket not available.");
        }
      } catch (err) {
        console.log(err);
      }
    },

    async emitEvent({  commit, state }, { event, payload = null }){
      try {
        const socket = state.socket
        socket.emit(event, payload)
      }
      catch (err) {
        console.log(err)
      }
    }
  },
};
