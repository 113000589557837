<template>
  <div v-show="routeDetails">
    <div class="accordion" role="tablist">
      <div no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <a href="javascript:void(0);" block v-b-toggle.details-accordion variant="info">Show More Details</a>
        </b-card-header>
        <b-collapse id="details-accordion" visible accordion="my-accordion" role="tabpanel">
          <b-card-body class="py-2" v-for="(value, key) in routeDetails" :key="key">
            <div>
              <div class="h6 mt-2 mb-2">
                <i class="bi bi-geo-alt"></i> {{ key }}
                <hr>
              </div>
              <b-card-text> State: <code>{{ value.countrySubdivisionName }}</code></b-card-text>
              <b-card-text> Country: <code>{{ value.country }}</code></b-card-text>
              <b-card-text> Municipality:  <code>{{ value.municipality }}</code></b-card-text>
              <b-card-text> Free Form Address:  <code>{{ value.freeformAddress }}</code></b-card-text>
            </div>
          </b-card-body>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  export default {
    name: 'MapRoutesDetails',
    setup(){
      const store = useStore()
      const routeDetails = computed(() => {
        return store.state.tomtom.routeDetails
      })

      return {
        routeDetails
      }
    }
  }
</script>

<style lang="scss" scoped>
  .accordion a {
    text-emphasis: none;
  }
</style>