<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end align-items-center">
        <div class="buttons">
          <button
            style="margin-right: 2px"
            :disabled="markers.length < 2 || isLoading"
            @click.prevent="calculateRoute"
            class="btn btn-outline-primary mb-2">
            <div
              v-show="isLoading"
              class="spinner-border text-warning spinner-border-sm"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            {{ isLoading ? "Calculating..." : "Calculate Route" }}
          </button>
          <button
            style="margin-left: 2px"
            :disabled="!markers"
            @click.prevent="clearMarkers"
            class="btn btn-outline-danger  mb-2">
            Clear Markers
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <i class="bi bi-stopwatch map-icons text-muted" v-show="travelTime" ></i> 
          <span style="margin-left: 15px" class="mr-auto" v-show="travelTime"
            ><small class="text-success"> TRAVEL TIME</small> <br />
            <div class="h6 text-mute">
              {{ travelTime }}
            </div>
          </span>
      </div>
      <div class="col-12 mt-3">
        <div class="d-flex align-items-center">
          <i class="bi bi-geo-alt map-icons text-muted" v-show="travelDistance"></i> 
          <span style="margin-left: 15px" class="mr-auto" v-show="travelDistance"
            ><small class="text-success"> TRAVEL DISTANCE </small> <br />
            <div class="h5 text-mute" >
              {{ travelDistance }}
            </div>
          </span>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="d-flex align-items-center">
          <i class="bi bi-hourglass map-icons text-muted" v-show="trafficDelays !== null"></i>
          <span style="margin-left: 15px" class="mr-auto" v-show="trafficDelays !== null"
            ><small class="text-success"> TRAFFIC DELAY </small> <br />
            <div class="h5 text-mute" >
              {{ trafficDelays }}
            </div>
          </span>
        </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <MapRoutesDetails/>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref } from "vue"
import toaster from "../../../../lib/toaster"
import MapRoutesDetails from './MapRoutesDetails.vue'

export default {
  name: "MapHeaders",
  components: {
    MapRoutesDetails
  },
  setup() {
    const store = useStore();
    const apiKey = process.env.VUE_APP_TOMTOM_API_KEY;
    const isLoading = ref(false);

    const markers = computed(() => {
      return store.state.tomtom.markers;
    });

    const routeSummary = computed(() => {
      return store.state.tomtom.routeSummary;
    });

    const travelTime = computed(() => {
      if (routeSummary.value && routeSummary.value.travelTimeInSeconds) {
        return secondsToHms(routeSummary.value.travelTimeInSeconds);
      }
      return null;
    })

    const trafficDelays = computed(() => {
      if (routeSummary.value ) {
        return secondsToHms(routeSummary.value.trafficDelayInSeconds) || 0;
      }
      return null;
    })

    function secondsToHms(data) {
      data = Number(data);
      var hour = Math.floor(data / 3600);
      var minute = Math.floor((data % 3600) / 60);
      var second = Math.floor((data % 3600) % 60);

      var hourDisplay =
        hour > 0 ? hour + (hour == 1 ? " hour, " : " hours, ") : "";
      var minuteDisplay =
        minute > 0 ? minute + (minute == 1 ? " minute, " : " minutes") : "";
      var secondsDisplay =
        second > 0 ? second + (second == 1 ? " second" : " seconds") : "";
      return hourDisplay + minuteDisplay
    }

    const travelDistance = computed(() => {
      if (routeSummary.value && routeSummary.value.lengthInMeters) {
        return `${(routeSummary.value.lengthInMeters / 1609.344).toFixed(
          1
        )} Miles`;
      }
      return null;
    });

    function clearMarkers() {
      store.dispatch("tomtom/removeMarkers");
    }

    async function calculateRoute() {
      if (!markers.value || markers.value.length < 2) {
        return;
      }
      isLoading.value = true;
      const routerOptions = {
        key: apiKey,
        locations: [],
      };
      markers.value.forEach((marker) => {
        routerOptions.locations.push(marker.getLngLat());
      });
      try {
        await store.dispatch("tomtom/calculateRoute", routerOptions);
      } catch (error) {
        toaster.error({
          message: "Failed to calculate route: " + error.message,
          position: "top",
          durration: 500,
        });
      }
      isLoading.value = false;
    }

    return {
      markers,
      isLoading,
      travelTime,
      routeSummary,
      clearMarkers,
      trafficDelays,
      travelDistance,
      calculateRoute,
    };
  },
};
</script>
<style scoped lang="scss">
  .map-icons{
    font-size: 25px;
  }
  // @media screen and (max-width: 768px) {
  //   .bottons button {
  //     margin-left: 5px;
  //   }
  // }
</style>
