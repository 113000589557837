<template>
  <ion-app>
    <Navbar></Navbar>
    <transition :name="transitionName">
      <div>
        <ion-router-outlet />
        <StatusLoader v-if="isLoading"/>
        <FloatingControllerButtons v-if="!isLoading && isAuthenticated"/>
      </div>
    </transition>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import Navbar from './components/modules/layout/Navbar.vue'
import StatusLoader from './components/modules/loaders/StatusLoader.vue'
import FloatingControllerButtons from './components/modules/floating-buttons/FloatingController.vue'
const LoaderModule = createNamespacedHelpers('loaders')
const AuthenticationModule = createNamespacedHelpers("authentication");

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    Navbar,
    StatusLoader,
    IonRouterOutlet,
    FloatingControllerButtons
  },
  data(){
    return{
      transitionName: null
    }
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },
  computed: {
    ...LoaderModule.mapGetters(['selectLoaders']),
    ...AuthenticationModule.mapGetters(["selectIsAuthenticated"]),

    isLoading(){
      return this.selectLoaders.length > 0 ? true : false
    },
    isAuthenticated(){
      return this.selectIsAuthenticated
    }
  }
});
</script>
<style  lang="scss">
  // @media screen and (min-width: 900px){
  //   .ConversationMessageModal {
  //   --height: 90%;
  //   --width: 22%;
  //   // position: absolute; 
  //   //top: 5%;--width: 90%;
  //   --border-radius: 25px;
  //   //display: block;
  //   }
  // }
</style>