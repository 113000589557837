// Written By : Nemese Kalubi
// Date: Thur, July 28, 2022

import axios from 'axios'

/**
 * @module intent.service
 * this file contains the intent feature
 */

export default {
  list() {
    return axios.get('/webhooks/ai/intent/list')
  },
  count() {
    return axios.get('/webhooks/ai/intent/count')
  },
  create(payload) {
    return axios.post('/webhooks/ai/intent',payload) 
  },
  invoke(payload) {
    return axios.post('/webhooks/ai/intent/invoke',payload) 
  },
}