<template>
  <div
    class="offcanvas offcanvas-bottom"
    style="height: 95%"
    data-bs-scroll="true"
    tabindex="-1"
    id="ControlCenter"
    aria-labelledby="ControlCenterLabel">
    <div class="offcanvas-header bg-light">
      <div v-if="isShown">
        <TerminalHeader/>
      </div>
      <div class="d-flex align-items-center">
       <div style="margin-right: 10px;">
        <nav class="navbar navbar-expand-lg bg-light">
          <div class="container-fluid">
            <a class="navbar-brand" href="javascript:void(0);"></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item" @click="setCurrentPage('terminal')">
                  <a class="nav-link active h6" aria-current="page" href="javascript:void(0);"><i class="bi bi-terminal"></i> Terminal</a>
                </li>
                <li class="nav-item" @click="setCurrentPage('maps')">
                  <a class="nav-link h6" href="javascript:void(0);"><i class="bi bi-geo-alt"></i> Maps</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link h6" href="javascript:void(0);"> <i class="bi bi-currency-dollar"></i>Pricing</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link h6"><i class="bi bi-bell-slash"></i> Notification</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
       </div>
        <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close">
      </button>
      </div>
    </div>
    <div class="offcanvas-body px-0 py-0">
      <Maps v-if="currentPage === 'maps' && isShown"/>
      <Terminal v-if="currentPage === 'terminal' && isShown" />
    </div>
  </div>
</template>

<script>
import Maps from '../../modules/maps/Maps.vue'
import Terminal from '../terminal/TerminalPage.vue'
import TerminalHeader from '../terminal/chats/ChatHeaders.vue'
import { createNamespacedHelpers } from 'vuex'

const ControlCenterModule = createNamespacedHelpers('controlCenter')

export default{
  name: 'ControlCenter',
  components: {
    Maps,
    Terminal,
    TerminalHeader,
  },
  computed: {
    ...ControlCenterModule.mapGetters(['selectCurrentPage', 'selectIsShown']),

    isShown(){
      return this.selectIsShown
    },
    currentPage(){
      return this.selectCurrentPage
    }
  },
  methods: {
    ...ControlCenterModule.mapActions(['setCurrentPage', 'setIsShown']),

    handleShowControl() {
      this.setIsShown(true)
    },
    handleHideControl() {
      this.setIsShown(false)
    }
  },
  mounted() {
    const controlCenter = document.getElementById('ControlCenter')
    controlCenter.addEventListener('show.bs.offcanvas', this.handleShowControl)
    controlCenter.addEventListener('hide.bs.offcanvas', this.handleHideControl)
  },
  unmounted() {
    const controlCenter = document.getElementById('ControlCenter')
    controlCenter.removeEventListener('show.bs.offcanvas', this.handleShowControl)
    controlCenter.removeEventListener('hide.bs.offcanvas', this.handleHideControl)
  }
}
</script>
