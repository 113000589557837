// Written By : Nemese Kalubi
// Date: Fri Apr 15, 2022

import store from '../../store'

/**
 * @module message-sent.event
 */

/**
 * message sent event
 * @param {JSON} data
 * @return none
 */
export default async (data) => {
  const { conversationId } = data

  await store.dispatch('conversation/handleSelectedConversation', {
    _id: conversationId 
  },{ root: true })
}