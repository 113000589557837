// Written By : Nemese Kalubi
// Date: Fri Apr 15, 2022

import store from '../../store'

/**
 * @module message-received.event
 */

/**
 * message received event
 * @param {JSON} data
 * @return none
 */
export default async (data) => {
  const currentConversation = store.getters["conversation/selectCurrentConversation"]
  const { conversationId } = data

  await store.dispatch("conversation/fetchConversations", {}, { root: true })

  // update selected conversation messsage
  if (currentConversation && currentConversation._id === conversationId) {
    await store.dispatch("message/fetchSelectedConvoMessages",
      { conversationId },
      { root: true }
    )
  }
}
