import EmailService  from '../../services/email.service';

export default {
  namespaced: true,

  state: {
    emailList: [],
    httpResponse: undefined
  },
  getters: {
    selectHttpResponse(state){
      return state.httpResponse
    }
  },
  mutations: {
    setEmailList(state, emails) {
      state.emailList = emails;
    },
    setHttpResponse(state, response) {
      state.httpResponse = response;
    }
  },
  actions: {
    async getEmailList({ commit }){
      const response = await EmailService.get();
      commit('setEmailList', response);
    },
  }
}