// Written By : Nemese Kalubi
// Date: Fri Apr 15, 2022

import store from '../../store'

/**
 * @module fetcht-temperature.event
 */

/**
 * fetch reports event
 * @param {JSON} data
 * @return none
 */
export default async (data) => {
  // dispatch report apis
  await store.dispatch('metrics/fetchMetrics',{ }, { root: true })
}