/**
 * @Author Nemese Kalubi
 * @Date Sun, March 20, 2022
 * @Reports service implementation
 */
import axios from "axios";

export default {
  list({ userId }) {
    return axios.get(`/reports/list/${userId}`)
  },
  fans(payload) {
    return axios.post("/reports/fans", {
      ...payload
    })
  },
  light(payload) {
    return axios.post("/reports/light", {
      ...payload
    })
  },
  humidity(payload) {
    return axios.post("/reports/humidity", {
      ...payload
    })
  },
  createReport(payload) {
    return axios.post('/reports/create', {
      ...payload
    })
  },
  updateReport({ userId, payload}) {
    return axios.put(`/reports/update/${userId}`, {
      ...payload
    })
  },
  deleteReport({ userId, payload}) {
    return axios.put(`/reports/delete/${userId}`, {
      ...payload
    })
  },
  updateWidget(payload){
    const { widgetId, ...update } = payload
    return axios.put(`/reports/update-widget/${widgetId}`, {
      ...update
    })
  },
  temperature(payload) {
    return axios.post('/reports/temperature', {
      ...payload
    })
  },
  messageCount(payload) {
    return axios.post('/reports/message-count', {
      ...payload
    })
  },
  connectionCount(payload) {
    return axios.post('/reports/connection-count', {
      ...payload
    })
  },
  LocalTemperature(payload) {
    return axios.post('/reports/local-temperature', {
      ...payload
    })
  },
  conversationCount(payload) {
    return axios.post('/reports/conversation-count', {
      ...payload
    })
  },
};
