<template>
  <div class="maps" ref="mapColRef">
    <div id="map" class="map" ref="tomtomMaps" :style="mapStyle">
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import tt from "@tomtom-international/web-sdk-maps";
import toaster from '../../../../lib/toaster';
export default {
  name: "Maps",
  setup() {
    const apiKey = process.env.VUE_APP_TOMTOM_API_KEY;
    const tomtomMaps = ref(null);
    const store = useStore();
    const homeLocation = [-111.973042, 40.367863];
    const mapColRef = ref('mapColRef')
    const columnWidth = ref(0)
    let map;

    onMounted(() => {
      map = tt.map({
        key: apiKey,
        center: homeLocation,
        language: "en-GB",
        zoom: 8,
        container: tomtomMaps.value,
      })

      map.addControl(new tt.FullscreenControl());
      map.addControl(new tt.NavigationControl());

      map.on("click", (event) => {
        const marker = new tt.Marker({
          color: 'red',
        }).setLngLat(event.lngLat).addTo(map);
        setMarkers(marker);
      });

      map.on("load", (event) => {
        map.resize();
        displayDefaultMarkers(homeLocation);
      })

      columnWidth.value = mapColRef.value.clientWidth
      window.addEventListener('resize', handleWindowResize) 
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleWindowResize)
    })
  
    let mapColWidth = computed(() => {
      return columnWidth.value
    })

    const mapStyle = computed(() => {
      return `height: 80vh; width: ${mapColWidth.value}vw`
    })

    function getDivDimensions() {
      return mapColRef.value.clientWidth
    }

    function handleWindowResize(event) {
      columnWidth.value = getDivDimensions()
    }

    const markers = computed(() => {
      return store.state.tomtom.markers;
    });

    const routeLayer = computed(() => {
      return store.state.tomtom.routeLayer;
    });

    const eventMarkers = computed(() => {
      return store.state.tomtom.eventMarkers
    });

    function setMarkers(marker) {
      store.dispatch("tomtom/setMarkers", marker);
    }

    function displayDefaultMarkers(location) {
      const size = 50;
      var marker = new tt.Marker({
        color: 'red',
      }).setLngLat(location).addTo(map);

      var popupOffsets = {
        top: [0, 0],
        bottom: [0, -40],
        'bottom-right': [0, -70],
        'bottom-left': [0, -70],
        left: [25, -35],
        right: [-25, -35]
      }

      var popup = new tt.Popup({
        size: size,
        offset: popupOffsets
      })
      .setHTML("Your location")
      marker.setPopup(popup).togglePopup()
      setMarkers(marker)
    }

    watch(routeLayer, function(value) {
      if (value && map) {
        displayRoute(value);
      }
    })

    watch(markers, function(m) {
      if(m.length === 0) {
        if(routeLayer.value){
          const foundRoute = map.getLayer('route')
          if(foundRoute){
            map.removeLayer(foundRoute.id)
            map.removeSource(foundRoute.id)
            store.dispatch('tomtom/setRouteLayer', null)
            store.dispatch('tomtom/setRouteSummary', null)
          }
        }
      }
    })

    watch(eventMarkers, function(locations) {
      if(locations.length){
        locations.forEach(location => {
          console.log({ location })
        const marker = new tt.Marker({
          color: 'red',
        }).setLngLat(location).addTo(map)
        setMarkers(marker)
      })
      }
    })

    function displayRoute(routeLayer) {
      if(routeLayer === null){
        return
      }
      const routeInProgress = map.getLayer('route')
      if (!routeInProgress){
        map.addLayer(routeLayer)
      }
      else {
        toaster.warn({
          message: `
          There a route already in progress. You must clear markers before calculating more routes.`,
          duration: 5000, // 5 seconds
          position: 'top'
        })
      }
    }

    return {
      apiKey,
      markers,
      mapStyle,
      tomtomMaps,
    };
  },
};
</script>

<style scoped lang="scss">
// #map {
//   height: 100vh;
//   width: 100vw;
// }
.locationMarker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: purple;
}
.iconMarker {
  width: 50px;
  height: 50px;
  position: relative;
  background-position: center;
  background-size: 45;
  border-radius: 50%;
}
</style>
