// Written By : Nemese Kalubi
// Date: Sat, March 20, 2022

/**
 * @module report-creation.util
 */

export default {
  // types
  TYPES: {
    CHART: "chart",
    WIDGET: "widget",
  },

  // steps
  STEPS: {
    SELECT_TYPE: "SELECT_TYPE",
    REVIEW_REPORT: "REVIEW_REPORT",
    CREATE_REPORT: "CREATE_REPORT",
    BUILD_CHART_REPORT: "BUILD_CHART_REPORT",
    BUILD_WIDGET_REPORT: "BUILD_WIDGET_REPORT",
    INITIATE_BUILD_REPORT: "INITIATE_BUILD_REPORT",
    SELECT_WIDGET_REPORT_KIND: "SELECT_WIDGET_REPORT_KIND",
  }
};
