// Written By : Nemese Kalubi
// Date: Fri Apr 15, 2022

import store from '../../store'

/**
 * @module fetch-reports.event
 */

/**
 * fetch reports event
 * @param {JSON} data
 * @return none
 */
export default async (data) => {
  // dispatch report apis
  await store.dispatch('report/fetchHumidityReport', { limit: 10 }, { root: true })
  await store.dispatch('report/fetchTemperatureReport', { limit: 10 }, { root: true })
  await store.dispatch('report/fetchSummaryChartReport', { limit: 15 }, { root: true })
}