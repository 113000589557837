<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-start mb-4 align-items-center">
      <div style="margin-right: 10px">
        <b-avatar :src="avatar" variant="primary" text="BV"></b-avatar>
      </div>
      <div class="chat-bb-left px-3 py-2 chat-bubble" style="background-color: #78e08f">
        <span class="chat-text">
          {{ chat.text }}
        </span>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-end mb-4 align-items-center">
      <div class="chat-bb-right px-3 py-2  chat-bubble" style="background-color: #82ccdd">
        <span class="chat-text">
          {{ chat.response.fulfillmentText }}
        </span>
      </div>
      <div style="margin-left: 10px">
        <b-avatar :src="agent.avatar" variant="primary" text="BV"></b-avatar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatItem",
  props: {
    chat: {
      type: Object,
      required: true,
    },
    agent: {
      type: Object,
      required: true,
    },
    avatar: {
      type: String,
      required: false
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-text {
  font-size: 18px;
  text-align: center;
}
.chat-bubble {
  max-width: 60%;
  border-radius: 10px;
  text-align: left;
  display: table-cell;
  vertical-align: 'center';
}
</style>
