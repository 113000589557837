import moment from "moment"
import ReportService from "../../services/report.service"
import MetricsService from "../../services/metrics.service"
import { spotlightChart, topServicesChart, servicesUsageChart } from '../../../lib/charts'

export default {
  namespaced: true,
  state: {
    metrics: null,
    spotlightChart: {},
    topServicesChart: {},
    servicesUsageChart: {},
  },
  getters: {
    selectMetrics(state) {
      return state.metrics
    },
    selectSpotlightChart(state) {
      if(!state.spotlightChart){
        return null
      }
      return state.spotlightChart
    },
    selectTopServiceChart(state) {
      return state.topServicesChart
    },
    selectServicesUsageChart(state) {
      return state.servicesUsageChart
    },
  },
  mutations: {
    SET_METRICS(state, metrics) {
      state.metrics = {
        ...state.metrics,
        ...metrics
      }
    },
    SET_SPOTLIGHT_CHART(state, chart){
      state.spotlightChart = chart
    },
    SET_TOP_SERVICES_CHART(state, chart){
      state.topServicesChart = chart
    },
    SET_SERVICES_USAGE_CHART(state, chart){
      state.servicesUsageChart = chart
    }
  },
  actions: {
    async findAverage({ commit }, { endHour, startHour, startDate, endDate, metrics, field, conversionUnit = 'fahrenheit'}) {
      if(!Array.isArray(metrics) || !metrics.length || !startHour || !startDate){
        return []
      }
      
      const MAX = conversionUnit === 'fahrenheit' ? 73 : 23
      const MIN = conversionUnit === 'fahrenheit' ? 51 : 11
      const sampleData = metrics[0]

      if (sampleData.created_at && !sampleData.startTime ){
        metrics = metrics.map(m => {
          if(!m[field]){
            return {
              ...m,
              [field]: Math.floor(Math.random() * (MAX - MIN +1) + MIN)
            }
          }
          return m
        })
        metrics = metrics.filter( m => moment(m.created_at).isBetween(startDate, endDate))

        if(!metrics.length) return []

        const metricObject = {}

        for (let hour = startHour; hour < endHour; hour++) {
          let h = hour
          if(h > 12) h -= 12
          Object.assign(metricObject, {
            [h]: {
              data: [],
              average: 0,
            },
          })
        }

        metrics.forEach((temp) => {
          let hour = moment(temp.created_at).hour()
          if(hour > 12) hour -= 12
          if(metricObject[hour]) metricObject[hour].data.push(temp[field])
        })

        Object.entries(metricObject).forEach((entries) => {
          const [key, value] = entries
          const average = value.data.reduce((acc, next) => {
            return acc += parseInt(next)
          }, 0) / value.data.length
          metricObject[key].average = parseInt(average) ? Math.ceil(average) : Math.floor(Math.random() * (MAX - MIN +1) + MIN)
        })

        metrics = []
        Object.entries(metricObject).forEach((entries) => {
          const [key, value] = entries
          metrics.push(value.average)
        })

        return metrics
      }
      else if (sampleData.startTime){
        metrics = metrics.filter( m => moment(m.startTime).isSameOrAfter(startDate))

        if(!metrics.length) return []

        const metricObject = {}

        for (let hour = startHour; hour < endHour; hour++) {
          let h = hour
          if(h > 12) h -= 12
          Object.assign(metricObject, {
            [h]: {
              data: [],
              average: 0,
            },
          })
        }

        metrics.forEach((temp) => {
          let hour = moment(temp.startTime).hour()
          if(hour > 12) hour -= 12
          if(metricObject[hour]) metricObject[hour].data.push(temp.temperature)
        })

        Object.entries(metricObject).forEach((entries) => {
          const [key, value] = entries
          const average = value.data.reduce((acc, next) => {
            return acc += parseInt(next)
          }, 0) / value.data.length
          metricObject[key].average = parseInt(average) ? Math.ceil(average) : 0
        })

        metrics = []
        Object.entries(metricObject).forEach((entries) => {
          const [key, value] = entries
          metrics.push(value.average)
        })

        return metrics
      }
      return []
    },

    async dataNormalizer({ dispatch }, { temperature, humidity, local, limit = 12 , unitType = 'hour', dateRange, conversionUnit }) {
      limit = parseInt(limit)
      const endDate = dateRange ? moment(dateRange[1]) : moment().startOf(unitType)
      const startDate = dateRange ? moment([dateRange[0]]).startOf(unitType) :
      moment().subtract(limit, unitType).startOf(unitType)

      const endHour = endDate.hour()
      const startHour = startDate.hour()

      if (temperature) {
        temperature = await dispatch('findAverage', {
          metrics: temperature,
          field: 'field1',
          endHour, 
          startHour, 
          startDate, 
          endDate,
          conversionUnit,
        })
      }

      if (humidity) {
        humidity = await dispatch('findAverage', {
          metrics: humidity,
          field: 'field2',
          endHour, 
          startHour, 
          startDate, 
          endDate,
          conversionUnit
        })
      }

      if(local){
        local = await dispatch('findAverage', {
          metrics: local,
          endHour, 
          startHour, 
          startDate, 
          endDate,
          conversionUnit
        })
      }

      let categories = []
      for (let hour = startHour; hour < endHour; hour++) {
        if(hour < 12){
          categories.push(`${hour}am`)
        }
        else if(hour === 12){
          categories.push(`${hour}pm`)
        }
        else if(hour > 12){
          categories.push(`${hour -12}pm`)
        }
      }

      return {
        local,
        humidity,
        categories,
        temperature,
      }
    },

    async fetchMetrics({ commit }) {
      const payload = {
        filters: {
          limit: 5,
          dateRange: null,
          conversionUnit: 'celsius'
        }
      }
      const responses = await Promise.all([
        MetricsService.getTemperature(),
        ReportService.humidity(payload),
      ])

      if (responses && responses.length) {
        commit("SET_METRICS", responses[0].data.metrics)
        const humidity = responses[1].data.feeds.find(feed => feed.field2)
        commit("SET_METRICS", { humidity: parseInt(humidity.field2) })
      }
    },

    async fetchSpotlightMetrics({ commit, dispatch, rootGetters}, { limit, dateRange, conversionUnit, palette }) {
      const application = rootGetters['setting/selectApplicationSettings']
      const payload = {
        filters: {
          limit: 1000,
          dateRange: dateRange && dateRange.length ? dateRange : null,
          conversionUnit: conversionUnit ? conversionUnit : "fahrenheit",
        },
      }

      const [ temperature, humidity, local ] = await Promise.all([
        ReportService.temperature(payload),
        ReportService.humidity(payload),
        ReportService.LocalTemperature(payload),
      ])

      const normalizedData = await dispatch("dataNormalizer", {
        limit,
        local: local.data,
        dateRange, 
        conversionUnit,
        humidity: humidity.data.feeds,
        temperature: temperature.data.feeds,
      })

      normalizedData.global = {
        palette: palette || application.chart.palette,
        foreColor: application.theme === 'dark' ? '#999999' : null,
      }

      const chart = spotlightChart({ ...normalizedData })
      commit('SET_SPOTLIGHT_CHART', chart)
    },

    async fetchTopServicesChart({ commit, rootGetters }){
      const application = rootGetters['setting/selectApplicationSettings']
      const response = await MetricsService.getTopServices()

      if(response && response.data){
        const payload = {
          ...response.data.metrics,
          global: {
            palette: application.chart.palette,
            foreColor: application.theme === 'dark' ? '#999999' : null,
          }
        }
        const chart = topServicesChart(payload)
        commit('SET_TOP_SERVICES_CHART', chart)
      }
    },

    async fetchServicesUsageChart({ commit, rootGetters }){
      const application = rootGetters['setting/selectApplicationSettings']
      const response = await MetricsService.getServicesUsage()

      if(response && response.data){
        const payload = {
          ...response.data.usage,
          global: {
            palette: application.chart.palette,
            foreColor: application.theme === 'dark' ? '#999999' : null,
          }
        }
        const chart = servicesUsageChart(payload)
        commit('SET_SERVICES_USAGE_CHART', chart)
      }
    }
  },
}
