// Written By : Nemese Kalubi
// Date: Fri, Sep 23, 2022

import axios from 'axios'

/**
 * @module action.service
 * this file contains the activities feature
 */

export default {
  search(query) {
    return axios.post(`/activity/search`, { ...query }) 
  },
  getLogs({ userId }) {
    return axios.get(`/activity/get-logs/${userId}`) 
  },
}