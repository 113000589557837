// Written By : Nemese Kalubi
// Date: Fri Apr 15, 2022

import store from '../../store'

/**
 * @module handleAgentResponseChat.event
 */

/**
 * handleAgentResponseChat
 * @param {JSON} chat agent chat response
 * @return none
 */
export default async ({ chat }) => {
  // dispatch agent handleAgentResponseChatEvent
  store.dispatch('agent/handleAgentResponseChatEvent', chat, { root: true })
}