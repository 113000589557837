// Written By : Nemese Kalubi
// Date: Wed, Aug 31, 2022

import axios from 'axios'

/**
 * @module action.service
 * this file contains the action feature
 */

export default {
  get(payload) {
    return axios.post('/webhooks/ai/action/get', payload) 
  },
  list() {
    return axios.get('/webhooks/ai/action/list')
  },
  count() {
    return axios.get('/webhooks/ai/action/count') 
  },
  create(payload) {
    return axios.post('/webhooks/ai/action/', payload) 
  },
  update(payload) {
    const { actionId } = payload
    return axios.put(`/webhooks/ai/action/update/${actionId}`, payload) 
  },
  delete(payload) {
    const { actionId } = payload
    return axios.delete(`/webhooks/ai/action/${actionId}`) 
  },
}