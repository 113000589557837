<template>
  <div class="row wrapper_div">
    <div class="col-12 scroll">
      <ChatList v-on:scroll="handleScroll" />
    </div>
    <div class="col-12 compose_div">
      <ComposeMessage />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import ChatList from "./chats/ChatList.vue";
import ComposeMessage from "./chats/ComposeMessage.vue";
import { createNamespacedHelpers } from 'vuex'
import Toaster from '../../../../lib/toaster';

const AgentModule = createNamespacedHelpers('agent')

export default {
  name: "ChatWindow",
  components: {
    ChatList,
    ComposeMessage,
  },
  computed: {
    ...AgentModule.mapGetters(['selectAllLoaded', 'selectWasUpdated']),
    
    allLoaded(){
      return this.selectAllLoaded
    },
    updated(){
      return this.selectWasUpdated
    }
  },

  watch: {
    updated: debounce(function(value) {
      if(value){
        console.log('Updated!!!')
        const elements = document.getElementsByClassName('scroll');
        elements.scrollTop = elements.scrollHeight + 200
      }
    })
  },

  methods: {
    ...AgentModule.mapActions(['fetchMoreAgentResponses', 'setIsLoadingMore']),
    async handleScroll(event){
      const { scrollTop, scrollHeight, offsetHeight } = event.target
      if( scrollTop === (scrollHeight - offsetHeight)){
        if(this.allLoaded) return
        this.setIsLoadingMore(true)
        try {
          await this.fetchMoreAgentResponses()
        } catch (error) {
          Toaster.error({
            title: "Error",
            position: 'top',
            message: error.message,
          })
        }
        this.setIsLoadingMore(false)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper_div {
  position: relative;
  height: 90%;
}
.compose_div {
  position: relative;
  bottom: 0px;
}
.scroll {
  max-height: 745px !important;
  overflow-y: auto;
}
@media screen and (max-width: 768px) {
  .scroll {
    max-height: 745px !important;
    overflow-y: auto;
  }
}
</style>
