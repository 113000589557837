// Written By : Nemese Kalubi
// Date : Fri August 27, 2022

import IntentService from '../../services/intent.service'
import SettingsService from "../../services/setting.service"
import SpeechRecognition from '../../../clients/speech-recognition/speech-recognition'

/**
 * @module speech-ecognition.module 
 */

export default {
  namespaced: true,
  state: {
    isSpeaking: false,
    recognition: null,
    isRecognizing: false,
    agentResponse: null,
  },
  getters: {
    selectVoiceList(state) {
      return SpeechRecognition.getVoiceList();
    },
    selectIsSpeaking(state){
      return state.isSpeaking
    },
    selectRecognition(state){
      return state.recognition;
    },
    selectAgentResponse(state){
      return state.agentResponse
    },
    selectIsRecognizing(state){
      return state.isRecognizing
    },
  },
  mutations: {
    SET_IS_SPEAKING: (state, status) => {
      state.isSpeaking = status
    },
    SET_IS_RECOGNIZING: (state, status) => {
      state.isRecognizing = status
    },
    SET_AGENT_RESPONSE: (state, response) => {
      state.agentResponse = response
    },
    SET_START_RECOGNITION: (state, recognition) => {
      state.recognition = recognition;
    },
    SET_SPEECH_RECOGNITION(state, recognition){
      state.recognition = recognition
    }
  },
  actions: {
    speak({ getters }, text = "") {
      if (!text || !text.length) {
        text = "The quick brown fox trips over the lazy dog."
      }
      SpeechRecognition.speak(text)
    },
    
    setIsSpeaking({ commit, dispatch}, status){
      commit('SET_IS_SPEAKING', status)
      if(status === false){
        dispatch('checkContinuousAndExecute')
      }
    },

    setIsRecognizing({ commit }, status){
      commit('SET_IS_RECOGNIZING', status)
    },

    stopRecognition: ({ commit }) => {
      SpeechRecognition.stopRecognition();
      commit("SET_START_RECOGNITION");
    },

    startRecognition({ commit }){
      SpeechRecognition.startRecognition();
      commit("SET_START_RECOGNITION", null);
    },

    setSelectedVoice({ commit }, voice) {
      const update = {
        field: 'voice',
        value: voice
      }
      SpeechRecognition.setUlteranceField(update)
    },

    checkContinuousAndExecute({ dispatch, getters }){
      const lastResponse = getters['selectAgentResponse']
      const { allRequiredParamsPresent, outputContexts: contexts } = lastResponse
      if(!allRequiredParamsPresent || (contexts && contexts.length )){
        dispatch('startRecognition')
      }
    },

    async invoke({ dispatch, commit, getters }, text){
      const lastResponse = getters['selectAgentResponse']
      
      const contextDetails = (lastResponse && !lastResponse.allRequiredParamsPresent) && 
      (lastResponse && lastResponse.outputContexts && lastResponse.outputContexts.length) ? 
      { contexts: lastResponse.outputContexts } : {}

      const response = await IntentService.invoke({ text, ...contextDetails })

      if(response && response.status === 200){
        commit("SET_AGENT_RESPONSE", response.data)
        const { fulfillmentText } = response.data
        if(fulfillmentText && fulfillmentText.length){
          if(fulfillmentText.includes('<translate>')){
            SpeechRecognition.speakTranslationText({ 
              text: fulfillmentText, 
              response: response.data
            })
          }
          else {
            dispatch('speak', fulfillmentText)
          }
        }
        else {
          dispatch('speak', "Sorry, I didn't get that. Would you mind saying that again?")
        }
      }
    },

    async fetchSpeechRecognitionSettings({ commit, rootGetters }){
      const { _id: userId } = rootGetters["user/selectCurrentUser"]
      const payload = { filters: { userIds: [ userId ] }}
      const response = await SettingsService.list(payload)
      if (response && response.data) {
        SpeechRecognition.initialize({ 
          speechRecognition: {
            voice: response.data.speechUlterance,
            recording: response.data.speechRecognition
          }
        })
        commit('SET_SPEECH_RECOGNITION', {
          voice: response.data.speechUlterance,
          recording: response.data.speechRecognition
        })
      }
    }
  }
}