<template>
  <div>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="isRecording ? 'danger' : isSpeaking ? 'primary': 'dark'">
        <ion-icon 
        :icon="isRecording ? recordingSharp : isSpeaking ? pauseSharp : layersSharp ">
        </ion-icon>
      </ion-fab-button>
      <ion-fab-list side="top">
        <ion-fab-button 
        color="secondary"
        data-bs-toggle="offcanvas" 
        data-bs-target="#ControlCenter" 
        aria-controls="ControlCenter">
          <ion-icon :icon="gridSharp">
          </ion-icon>
        </ion-fab-button>
        <ion-fab-button color="success">
          <ion-icon :icon="schoolSharp"></ion-icon>
        </ion-fab-button>
        <ion-fab-button 
          color="primary">
          <ion-icon :icon="share"></ion-icon>
        </ion-fab-button>
        <ion-fab-button color="danger"
         @click.prevent="startRecording">
          <ion-icon :icon="micSharp"></ion-icon>
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>

    <!-- drawers section start -->
    <ControlCenter/>
    <!-- drawers section end -->
  </div>
</template>
<script>
import { Device } from '@capacitor/device'
import Toaster from '../../../../lib/toaster'
// import capacitorPlugin from '../../../../clients/capacitor/capacitor-plugin'
import { IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/vue'
import { share, micSharp, gridSharp,layersSharp, recordingSharp, pauseSharp, schoolSharp } from 'ionicons/icons'
import { createNamespacedHelpers } from 'vuex'
import ControlCenter from '../control-center/ControlCenter.vue'

const SpeechRecognitionModule = createNamespacedHelpers('speechRecognition')

export default {
  name: 'FloatingController',
  components: { 
    IonFab, IonFabButton, IonFabList, IonIcon, ControlCenter
  },
  setup() {
    return {
      share, gridSharp, micSharp, layersSharp, recordingSharp, pauseSharp, schoolSharp,
    }
  },
  computed: {
    ...SpeechRecognitionModule.mapGetters(['selectIsRecognizing', 'selectIsSpeaking']),

    isSpeaking(){
      return this.selectIsSpeaking
    },
    isRecording(){
      return this.selectIsRecognizing
    }
  },
  methods: {
    ...SpeechRecognitionModule.mapActions(['startRecognition']),

    async startRecording() {
      try{
        if(await this.isFeatureAvailable()){
          this.startRecognition()
        }
        else {
          Toaster.warn({
            position: "top",
            message: 'Sorry, this feature is not available on mobile yet.'
          })
        }
      }
      catch(err){
        Toaster.error({
          position: "top",
          message: 'Something went wrong. Try again in a bit.'
        })
      }
    },

    // async capacitorRecord (){
    //   try {
    //     if (await this.isFeatureAvailable()){
    //       const match = capacitorPlugin.startRecognition()
    //       console.log({ match })
    //     }
    //   }
    //   catch (err){
    //     console.log({ message: err.message })
    //   }
    // },
    async isFeatureAvailable(){
      const info = await Device.getInfo()
      return info.platform === 'web'
    }
  },
  // async mounted() {
  //   try {
  //     await capacitorPlugin.initialize()
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
}
</script>