import AuthService from '../../services/auth.service'
import router from "@/router"
import StorageUtil from '../../../lib/storage-util'

export default {
  namespaced: true,

  state: {
    token: undefined,
    username: '',
    password: '',
    isLoading: false,
    httpResponse: undefined,
    errorResponse: {},
    isAuthenticated: false,
  },
  getters: {
    selectHttpResponse(state){
      return state.httpResponse
    },
    selectIsAuthenticated(state){
      return state.isAuthenticated
    }
  },
  mutations: {
    setIsAuthenticated(state, status) {
      state.isAuthenticated = status
    },
    setToken(state, token) {
      state.token = token
    },
    setHttpResponse(state, response) {
      state.httpResponse = response
    },
    setUsername(state, username) {
      state.username = username
    },
    setPassword(state, password) {
      state.password = password
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setErrorResponse(state, response) {
      state.errorResponse = response
    },
  },
  actions: {
    async findUserAccount({ commit }, payload){
      const response = await AuthService.findUserAccount(payload);
      commit('setHttpResponse', response);
    },
    async sendVerificationCode({ commit }, email){
      const response = await AuthService.sendCode(email);
      commit('setHttpResponse', response);
    },
    async verifyVerificationCode({ commit }, payload){
      const response = await AuthService.verifyCode(payload)
      commit('setHttpResponse', response);
    },
    async sendResetPasswordRequest({ commit }, payload){
      const response = await AuthService.resetPassword(payload)
      commit('setHttpResponse', response);
    },
    async login({ commit, dispatch, state, rootState }) {
      commit('setIsLoading', true)
      commit('setErrorResponse', {})
      const { username, password } = state

      try {
        const response = await AuthService.login({ username, password })
        if (response && response.data && response.data.authenticated) {
          await dispatch('user/fetchUser', username, { root: true })
          commit('setToken', response.data.token)
          commit('setIsAuthenticated', true)

          // write the token to localstorage 
          StorageUtil.write('token', response.data.token)

          // fetch the user settings
          await dispatch('setting/fetchActiveUserSettings', {}, { root: true })

          // connect to the websocket
          const socket = rootState.websocket.socket

          if(!socket || !socket.connected){
            // connect to the socket
            await dispatch('websocket/connectSocket', {}, { root: true })
          }

          setTimeout(() => {
            commit("setIsLoading", false)
            router.push({ name: "Dashboard" }) // redirect 
          }, 1000)
        } else {
          commit('setIsLoading', false)
          commit('setErrorResponse', {
            data: 'You entered an incorrect username or password',
          })
        }
      }
      catch (err) {
        commit('setIsLoading', false)

        if (err.response.status === 400) {
          commit('setErrorResponse', err.response)
        }

        console.error(err)
      }
    },
    async logout({ commit, state }) {
      try {
        const token = state.token
        const response = await AuthService.logout({ token })
        if(response && response.data.success) {
          commit('setToken', '')
          commit('setIsAuthenticated', false)
          commit('setHttpResponse', response)
          StorageUtil.write('token', '')
          await commit('avatar/setAvatar', null, { root: true })
          await commit('user/setActiveUser', null, { root: true })
          await commit('websocket/setSocket', null, { root: true })
        }
      }
      catch (err) {
        console.error(err)
      }
    },
  },
}
