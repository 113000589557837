import axios from 'axios'

export default {
  get(userId) {
    return axios.get(`/profile/${userId}`)
  },

  update({ id, update }) {
    return axios.put(`/profile/${id}`, {
      ...update,
    })
  },

  destroy({ id }) { // danger
    return axios.delete(`/profile/${id}`)
  }
}