import ProfileService from '../../services/profile.service'

export default {
  namespaced: true,

  state: {
    profile: null,
    httpResponse: null,
  },
  getters: {
    selectProfile: (state) => {
      return state.profile
    }
  },
  mutations: {
    setProfile: (state, profile) => {
      state.profile = profile;
    },
    setHttpResponse(state, response) {
      state.httpResponse = response;
    }
  },
  actions: {
    async fetchProfile({ commit }, userId) {
      try {
        const profile = await ProfileService.get(userId)
        if (profile && profile.data) {
          commit('setProfile', profile.data)
        }
      }
      catch (err) {
        const { data } = err.response;
        commit('setHttpResponse', data)
      }
    },
    async updateProfile({ commit, rootState, dispatch }, update ) {
      try {
        const { _id: id } = rootState.profile.profile
        const { _id: userId } = rootState.user.activeUser
        const { data } = await ProfileService.update({ id, update })

        if(data.success) {
          await dispatch('profile/fetchProfile', userId, { root: true })
        }
        commit('setHttpResponse', data)
        return data
      }
      catch (err) {
        console.log(err)
      }
    },
    async destroyProfile({ commit }, { id }) {
      try {
        const res = await ProfileService.destroy({ id })
        console.log(res)
        commit('setHttpResponse', res)
      }
      catch (err) {
        console.log(err)
      }
    }
  }
}