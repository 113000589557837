<template>
  <div class="row">
    <div class="col-lg-3 bg-light d-none d-lg-block d-xl-block">
        <AgentDetails/>
      </div>
      <div class="col-lg-6 chat-window d-md-block d-lg-block d-sm-block d-xs-block">
        <ChatWindow/>
      </div>
      <div class="col-lg-3 bg-light d-none d-lg-block d-xl-block">

      </div>
  </div>
</template>

<script>
  import ChatWindow from './ChatWindow.vue'
  import AgentDetails from './AgentDetails.vue'
  export default {
    name: 'TerminalContents',
    components: {
      ChatWindow,
      AgentDetails,
    }
  }
</script>

<style lang="scss" scoped>
.chat-window {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
</style>