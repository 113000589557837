<template>
  <div class="maps">
    <div class="row px-2 py-0 map-container">
      <div class="col-md-10 col-sm-12 mb-2 map-content">
        <MapContents />
      </div>
      <div class="col-md-2 col-sm-12 col-xs-12 mb-2 map-headers">
        <MapHeaders/>
      </div>
    </div>
  </div>
</template>

<script>
import MapHeaders from './MapHeaders.vue'
import MapContents from "./MapContents.vue";
export default {
  name: "Maps",
  components: {
    MapHeaders,
    MapContents,
  },
};
</script>
<style scoped lang="scss">
  .map-container {
    display: flex;
  }
  @media screen and (max-width: 768px) {
    .map-content {
      order: 1;
    }
    .map-headers {
      order: 0;
    }
  }
</style>