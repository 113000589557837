/**
 * User service implementation
 */
import axios from "axios";

export default {
  get(){
    return axios.get('/email')
  },
  sendCode(email){
    return axios.post('/auth/send-code', {
      ...email,
    })
  },
  verifyCode(payload){
    return axios.post('/auth/verify-code', {
      ...payload
    })
  },
  resetPassword(payload){
    return axios.post('/auth/reset-password', {
      ...payload
    })
  },
}