import HyacinthService from "../../services/hyacinth.service";
import StorageUtil from '../../../lib/storage-util'

export default {
  namespaced: true,
  state: {
    hyacinth: null,
    currentPage: null,
  },
  getters: {
    selectHyacinth(state){
      return state.hyacinth;
    },
    selectCurrentPage(state){
      return state.currentPage
    },
  },
  mutations: {
    SET_CURRENT_PAGE(state, page){
      state.currentPage = page
      StorageUtil.write('currentPage', page)
    },

    SET_HYACINTH_SETTINGS: (state, settings) => {
      state.hyacinth = settings;
    },
    
    CHANGE_HYACINTH_PROPERTY: (state, payload) => {
      const properties = Object.getOwnPropertyNames(payload)
      if(properties){
        properties.forEach(prop => {
          state.hyacinth.settings[prop] = {
            ...state.hyacinth[prop],
            ...payload[prop]
          }
        })
      }
    },
  },
  actions: {
    setCurrentPage({ commit }, page) {
      commit('SET_CURRENT_PAGE', page)
    },
    
    async changeHyacinthProperty({ commit, dispatch }, payload ){
      commit('CHANGE_HYACINTH_PROPERTY', payload)
      await dispatch('updateHyacinthSettings')
    },

    async updateHyacinthSettings({ getters }, payload){
      const { _id, ...hyacinth } = getters['selectHyacinth']
      payload = payload ? payload : hyacinth
      await HyacinthService.update({ id: _id, payload: payload } )
    }
  },
};
