/**
 * User service implementation
 */
import config from "../config";
const { socketConnection } = config;
import { io } from "socket.io-client";
import storageUtil from "../../lib/storage-util";

export default {
  connect(){
    
    return new Promise((resolve, reject) => {
      try {
        const conn = {
          url: socketConnection,
          path: "/ozunu",
        }        
        const socket = new io(`${conn.url}`, { path: `${conn.path}`, query:`token=${storageUtil.read('token')}`})
        resolve(socket)
      }
      catch (error) {
        console.log(error)
        reject(error)
      }
    })
  }
}
