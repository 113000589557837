<template>
  <div v-if="isAuthenticated">
    <ion-menu :side="menuPosition" content-id="menuContent" :style="settings.bg">
      <ion-header>
        <ion-toolbar :style="settings.bg">
          <ion-title>Menu</ion-title>
        </ion-toolbar>
        <ion-progress-bar v-if="isLoading" type="indeterminate"></ion-progress-bar>
      </ion-header>
      <ion-content :style="settings.bg">
        <ion-list :style="settings.bg">
          <ion-item :style="settings.bg"
            class="menu-items"
            v-for="menu in menuItems"
            :key="menu.id"
            @click="routeTo(menu.name)"
          >
            <ion-icon class="text-muted" :icon="menu.icon" slot="start"></ion-icon>
            <ion-label>{{ menu.alias }}</ion-label>
          </ion-item>
          <ion-accordion-group :style="settings.bg">
            <ion-accordion value="Settings" style="--background: 'white'">
              <ion-item slot="header" :style="settings.bg">
                <ion-icon class="text-muted" :icon="settingsOutline" slot="start"></ion-icon>
                <ion-label>Settings</ion-label>
              </ion-item>

              <ion-list slot="content" class="settings-item-list" :style="settings.bg">
                <ion-item v-for="setting in settingItems" :key="setting.id" :style="settings.bg">
                  <ion-label class="setting-item" @click="routeTo(setting.name)">{{ setting.name }} </ion-label>
                </ion-item>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </ion-list>
      </ion-content>
      <div class="user-avatar" :style="settings.bg">
        <Avatar class="mb-2"></Avatar>
        <p class="text-muted text-center">
          {{ greetings }}, {{ currentUserName }}
        </p>
      </div>
      <div class="login-btn text-center" :style="settings.bg">
        <button 
        class="btn btn-outline-primary"
        @click="handleAuthentication">
        {{
          isAuthenticated ? "Sign out" : "Sign In"
        }}</button>
      </div>
    </ion-menu>
    <ion-router-outlet id="menuContent"></ion-router-outlet>
    <ion-header>
      <ion-toolbar :style="settings.bg">
        <ion-buttons :slot="menuPosition">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ pageTitle !== 'Dashboard' ? pageTitle : '' }} </ion-title>
        <ion-progress-bar v-if="isLoading" type="indeterminate"></ion-progress-bar>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding"></ion-content>
  </div>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonTitle,
  IonMenuButton,
  IonToolbar,
  IonButtons,
  IonLabel,
  IonIcon,
  IonAccordionGroup,
  IonAccordion,
  IonProgressBar,
} from "@ionic/vue";
import {
  pulseOutline,
  mailOutline,
  layersOutline,
  settingsOutline,
  homeOutline,
  notificationsOutline,
  searchOutline,
  personOutline,
} from "ionicons/icons";
import moment from "moment";
import router from "@/router";
import { v4 as uuidv4 } from "uuid";
import { mapGetters, mapActions } from "vuex";
import { createNamespacedHelpers } from "vuex";
import Avatar from "../avatar/Avatar.vue";

const SettingsModule = createNamespacedHelpers('setting')
const UserModule = createNamespacedHelpers("user");
const LoaderModule = createNamespacedHelpers('loaders')
const AuthenticationModule = createNamespacedHelpers("authentication");

export default {
  name: "Navbar",
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonMenuButton,
    IonLabel,
    IonRouterOutlet,
    IonTitle,
    IonButtons,
    IonToolbar,
    Avatar,
    IonIcon,
    IonAccordionGroup,
    IonAccordion,
    IonProgressBar,
  },
  setup() {
    return {
      personOutline,
      mailOutline,
      layersOutline,
      settingsOutline,
      notificationsOutline,
      searchOutline,
      homeOutline,
      pulseOutline,
    };
  },
  data() {
    return {
      menuItems: [
        {
          id: uuidv4(),
          name: "Dashboard",
          alias: "Dashboard",
          path: "/dashboard",
          icon: homeOutline,
        },
        {
          id: uuidv4(),
          path: '/profile',
          name: 'Profile',
          alias: 'Profile',
          icon: personOutline
        },
        {
          id: uuidv4(),
          name: "Message",
          alias: "Message",
          path: "/message",
          icon: mailOutline,
        },
        {
          id: uuidv4(),
          name: "Hyacinth",
          alias: "Hyacinth",
          path: "/hyacinth",
          icon: layersOutline,
        },
        {
          id: uuidv4(),
          name: "Activities",
          alias: "Activity Logs",
          path: "/activities",
          icon: pulseOutline,
        },
        {
          id: uuidv4(),
          name: "Notification",
          alias: "Notification",
          path: "/notification",
          icon: notificationsOutline,
        },
      ],
      settingItems: [
        // {
        //   id: uuidv4(),
        //   icon: '',
        //   path: '/profile',
        //   name: 'Profile',
        //   alias: 'profile',
        // },
        {
          id: uuidv4(),
          icon: '',
          path: '/system',
          name: 'System',
          alias: 'system',
        },
        {
          id: uuidv4(),
          icon: '',
          path: '/account',
          name: 'Account',
          alias: 'account',
        },
      ],
      avatarImage: "",
      currentOption: null,
    };
  },
  computed: {
    ...mapGetters(["selectPageTitle"]),
    ...AuthenticationModule.mapGetters(["selectIsAuthenticated"]),
    ...UserModule.mapGetters(["selectFirstname", "selectCurrentUser"]),
    ...SettingsModule.mapGetters(['selectUserSettings', 'selectApplicationSettings']),
    ...LoaderModule.mapGetters(['selectLoaders']),

    isLoading(){
      return this.selectLoaders.length > 0 ? true : false
    },
    settings(){
      return this.selectUserSettings
    },
    pageTitle(){
      return this.selectPageTitle
    },
    greetings() {
      const currentHour = moment().format("HH");
      if (currentHour >= 3 && currentHour < 12) {
        return "Good morning";
      } else if (currentHour >= 12 && currentHour < 15) {
        return "Good afternoon";
      } else if (currentHour >= 15 && currentHour < 20) {
        return "Good evening";
      } else if (currentHour >= 20 && currentHour < 3) {
        return "Good night";
      } else {
        return "Hello";
      }
    },
    isAuthenticated() {
      return this.selectIsAuthenticated;
    },
    currentUserName() {
      return this.isAuthenticated ? this.selectFirstname : "Menu";
    },
    menuPosition(){
      const app = this.selectApplicationSettings
      let position = ''

      if(app){
        position = app.menuPosition === 'left' ? 'start' : app.menuPosition === 'right' ? 'end' : 'start'
      }

      return position
    }
  },
  methods: {
    ...mapActions(["navigateTo", "navigateToOption"]),
    ...AuthenticationModule.mapActions(["logout"]),

    async routeTo(path) {
      try {
        await this.navigateTo(path);
      } catch (err) {
        console.log(err);
      }
    },
    async routeToOption(path) {
      await this.navigateToOption(path);
    },
    async handleAuthentication() {
      if (this.isAuthenticated) {
        await this.logout();
        await router.push({ name: "Login" });
      } else {
        await router.push({ name: "Login" });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.login-btn {
  margin-bottom: 15px;
}
.user-avatar {
  margin: auto;
  margin-bottom: 10px;
}
ion-list .menu-items {
  cursor: pointer;
}
ion-item .setting-item {
  margin-left: 55px;
}
.settings-item-list ion-item {
  --border-style: none
}
ion-menu {
  background-color: transparent !important;
}


</style>
