/**
 * Author: Nemese Kalubi
 * Date: Oct 07,2021
 */

export default {
  read: (field)=> {
    return localStorage.getItem(field);
  },
  write: (field, value )=> {
    localStorage.setItem(field, value);
  }
}