import { toastController } from '@ionic/vue';

export default {
  open: async (options) => {
    const toast = await toastController
      .create({
        ...options,
      })
    return  toast.present()
  },
  success: async (options) => {
		const { duration, header } = options;
    const toast = await toastController
      .create({
        color: 'success',
        header: header ? header : 'Success',
        duration: duration ? duration : 3000,
        ...options,
      })
		return toast.present();
  },
  error: async (options) => {
		const { duration, header } = options
    const toast = await toastController
      .create({
        color: 'danger',
        header: header ? header : 'Error',
        duration: duration ? duration : 3000,
        ...options,
      })
    return toast.present()
  },
  warn: async (options) => {
		const { duration, header } = options;
    const toast = await toastController
      .create({
        color: 'warning',
        header: header ? header : 'Warning',
        duration: duration ? duration : 3000,
        ...options,
      })
    return toast.present()
  },
  info: async (options) => {
		const { duration, header} = options;
    const toast = await toastController
      .create({
        color: 'primary',
        header: header ? header : 'Information',
        duration: duration ? duration : 3000,
        ...options,
      })
    return toast.present()
  },
};
