// Written By : Nemese Kalubi
// Date: Fri Apr 15, 2022

import messageSent from './message-sent.event'
import fetchReports from './fetch-reports.event'
import fetchTemperatureEvent from './fetch-temperature.event'
import messageReceivedEvent from "./message-received.event"
import displayRouteInfoEvent from './displayRouteInfo.event'
import handleAgentResponseChatEvent from './handleAgentResponseChat.event'

/**
 * @module event.router
 */

export default {
  MessageSentEvent: messageSent,
  FetchReportsEvent: fetchReports,
  MessageReceivedEvent: messageReceivedEvent,
  displayRouteInfoEvent: displayRouteInfoEvent,
  fetchTemperatureEvent: fetchTemperatureEvent,
  handleAgentResponseChatEvent: handleAgentResponseChatEvent
}