// Written By : Nemese Kalubi
// Date: Thur, July 28, 2022

import IntentService from '../../services/intent.service'

/**
 * @module intent.module
 * this file contains the intent store module
 */

export default {
  namespaced: true,
  
  state: {
    intents: [],
    intentCount: null,
  },
  getters: {
    selectIntents: (state) => (options = null) =>{
      return options ? state.intents.slice(options.startIndex, options.endIndex) : state.intents
    },
    selectAllIntents: (state) => {
      return state.intents
    },
    selectIntentCount(state){
      return state.intentCount
    }
  },
  mutations: {
    SET_INTENTS(state, intents){
      state.intents = intents
    },
    SET_INTENT_COUNT(state, count){ 
      state.intentCount = count
    }
  },
  actions: {
    async loadNextPage({ commit }, { startIndex, endIndex }){
      const response = await IntentService.list()
      if(response && response.data && response.data.length){
        let intents = response.data
        intents = intents.slice(startIndex, endIndex)
        commit('SET_INTENTS', intents)
      }
    },

    async searchIntents({ commit, dispatch, getters  }, searchString){
      if(searchString && !searchString.length){
        await dispatch('fetchIntents')
      }
      else {
        await dispatch('fetchIntents')
        let intents = getters.selectIntents()
        const regex = new RegExp(searchString, 'i')
        intents = intents.filter(intent => {
          return regex.test(intent.displayName) || regex.test(intent.name)
        })
        commit('SET_INTENTS', intents)
      }
    },

    async fetchIntents({ commit }){
      const response = await IntentService.list()
      commit('SET_INTENTS', response.data)
    },

    async fetchIntentCount({ commit, rootGetters }) {
      const user = rootGetters['user/selectCurrentUser']
      const response = await IntentService.count({
        userId: user._id,
      })

      if(response && response.data){
        commit('SET_INTENT_COUNT', response.data.count)
      }
    },
  }
}