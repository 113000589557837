// Written By : Nemese Kalubi
// Date: Fri, Oct 1, 2022

import axios from 'axios'
import tt from '@tomtom-international/web-sdk-services';

/**
 * @module tomtom.service
 * this file contains the tomtom feature
 */

export default {
  searchGeocode(payload) {
    return axios.post('/webhooks/ai/tomtom/search-geocode', payload)
  },
  calculateRoute({ key, locations }) {
    return tt.services.calculateRoute({
      key: key,
      travelMode: 'car',
      locations: locations
    })
  },
  calculateRouteExtended(payload) {
    return axios.post('/webhooks/ai/tomtom/calculate-route', payload)
  }
}