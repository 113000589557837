<template>
  <div class="">
    <div>
      <b-avatar
        :src="agent ? agent.avatar : ''"
        size="4rem"
        class="btn"
        id="agent-popover">
      </b-avatar>
      <span class="mx-2" style="font-size: 20px">
        {{ agent ? agent.name : "" }}
      </span>
    </div>
    <b-popover target="agent-popover" placement="right" triggers="click blur" >
      <template class="bg-white" #title>{{ agent ? agent.name : "" }}</template>
      <div class="">
        <div class="d-flex align-items-center justify-content-space-between">
          <div>
            <i style="font-size: 35px; margin-right: 20px;" class="bi bi-volume-mute"></i>
          </div>
          <div class="">
            <div class="btn btn-light ">
              <span class="px-3">Mute Agent</span>
            </div>
            <hr>
            <div class="text-muted">
              <p>Agent will be not announce incoming messages or notifications</p>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-space-between">
          <div>
            <i style="font-size: 35px; margin-right: 20px;" class="bi bi-volume-mute"></i>
          </div>
          <div class="">
            <div class="btn btn-light ">
              <span class="px-3">Mute Agent</span>
            </div>
            <hr>
            <div class="text-muted">
              <p>Agent will be not announce incoming messages or notifications</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <ul class="list-group">
        <div class="btn btn-light d-flex align-items-center justify-content-space-between">
          <i style="font-size: 24px; margin-right: 20px;" class="bi bi-volume-mute"></i>
          <span class="px-2">Mute Agent</span>
        </div>
      </ul> -->
    </b-popover>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const AgentModule = createNamespacedHelpers("agent");

export default {
  name: "TerminalHeader",
  computed: {
    ...AgentModule.mapGetters(["selectAgent"]),

    agent() {
      return this.selectAgent;
    },
  },
};
</script>

<style lang="scss" scoped></style>
