// Written By : Nemese Kalubi
// Date: Wed, Aug 31, 2022

import ActionService from '../../services/action.service'

/**
 * @module action.module
 * this file contains the action store module
 */

export default {
  namespaced: true,
  state: {
    count: 0,
    actions: [],
  },
  getters: {
    selectAction: state => id => {
      return state.actions.find(action => action.id === id)
    },
    selectCount(state){
      return state.count
    },
    selectActions(state){
      return state.actions
    },
  },
  mutations: {
    SET_COUNT(state, count) {
      state.count = count
    },
    SET_ACTIONS(state, actions){
      state.actions = actions
    }
  },
  actions: {
    async fetchCount({ commit }){
      const response = await ActionService.count()
      if(response && response.data){
        commit('SET_COUNT', response.data.count)
      }
    },
    async fetchActions({ commit }, { startIndex, endIndex }){
      const response = await ActionService.list()
      if(response && response.data){

        if( startIndex || endIndex ){
          commit('SET_ACTIONS', response.data
            .actions.slice(startIndex, endIndex))
        }
        else {
          commit('SET_ACTIONS', response.data.actions)
        }
      }
    },
    async createAction({ dispatch }, payload){
      const response = await ActionService.create({
        ...payload
      })
      if(response && response.data.success){
        await dispatch('fetchActions')
      }
    },
    async updateAction({ dispatch }, payload){
      const response = await ActionService.update({
        ...payload
      })
      if(response && response.data.success){
        await dispatch('fetchActions')
      }
    },
    async deleteAction({ dispatch }, actionId){
      const response = await ActionService.delete({
        actionId
      })
      if(response && response.data.success){
        await dispatch('fetchActions')
      }
    },
    async searchActions({ commit, dispatch, getters  }, searchString){
      if(searchString && !searchString.length){
        await dispatch('fetchActions', {})
      }
      else {
        await dispatch('fetchActions', {})
        let actions = getters.selectActions
        const regex = new RegExp(searchString, 'i')
        actions = actions.filter(action => {
          return regex.test(action.name) || regex.test(action.service) || regex.test(action.method)
        })
        commit('SET_ACTIONS', actions)
      }
    },
  }
}