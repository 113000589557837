// Written By : Nemese Kalubi
// Date: Fri, Oct 14, 2022

export default {
  namespaced: true,
  state: {
    isShown: false,
    currentPage: 'terminal'
  },
  getters: {
    selectIsShown(state){
      return state.isShown
    },
    selectCurrentPage(state){
      return state.currentPage
    }
  },
  mutations: {
    SET_IS_SHOWN(state, isShown){
      state.isShown = isShown
    },
    SET_CURRENT_PAGE(state, page){
      state.currentPage = page
    }
  },
  actions: {
    setIsShown({ commit }, isShown){
      commit('SET_IS_SHOWN', isShown)
    },
    setCurrentPage({ commit }, page) {
      commit('SET_CURRENT_PAGE', page)
    }
  }
}