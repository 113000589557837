<template>
  <div style="width: 100%">
    <div class="col-12 mb-3">
      <div class="input-group">
        <b-form-textarea
          id="textarea-no-resize"
          placeholder="type message"
          max-rows="4"
          no-resize
          v-model="message"
          v-on:keyup.enter="reply"
        ></b-form-textarea>
        <div class="input-group-text btn btn-light" @click.prevent="reply">
          <i v-if="!isLoading" class="bi bi-send send-btn-icon"></i>
          <b-spinner v-else variant="info" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Toaster from '../../../../../lib/toaster';
const SpeechRecognitionModule = createNamespacedHelpers('speechRecognition')

export default {
  name: "ComposeMessage",
  data(){
    return {
      message: null,
      isLoading: false,
    }
  }, 
  methods: {
    ...SpeechRecognitionModule.mapActions(['invoke']),

    async reply(){
      this.isLoading = true
      if(this.message && this.message.length){
        try {
          await this.invoke(this.capitalizeFirstLetter(this.message.trim()))
          this.message = null
        } catch (error) {
          Toaster.error({
            title: "Error",
            position: 'top',
            message: error.message
          })
        }
      }
      this.isLoading = false
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
};
</script>

<style lang="scss" scoped>
.send-btn-icon {
  font-size: 32px;
  // background-color: rgba(0,0,0,0.3) !important;
}
</style>
