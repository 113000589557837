import axios from 'axios';
import store from '../store'
import StorageUtil from '../../lib/storage-util'

export default{
  login(payload){
    return axios.post('/auth/login', {
       ...payload
    })
  },
  logout(payload){
    return axios.post('/auth/logout',{
       ... payload
      }
    )
  },
  sendCode(payload){
    return axios.post('/auth/send-code', {
      ...payload
    })
  },
  verifyJWT(token){
    return axios.post('/auth/verify', {
      token: token
    })
  },
  verifyCode(payload){
    return axios.post('/auth/verify-code', {
      ...payload
    })
  },
  resetPassword(payload){
    return axios.post('/auth/reset-password', {
      ...payload
    })
  },
  findUserAccount(payload){
    return axios.post('/auth/find-user-account', {
      ...payload
    })
  },
  async handleAuthentication(){
    let isAuthenticated = store.state.authentication.isAuthenticated

    if(isAuthenticated){
      return true
    }else {
      const token = StorageUtil.read('token')
      if(token) {
        const user = await this.verifyJWT(token);
        
        if(user && user.data) {
          store.commit('authentication/setIsAuthenticated', true)

          // connect to the websocket server
          const socket = store.state.websocket.socket

          if(!socket || !socket.connected){
            await store.dispatch('websocket/connectSocket', {}, { root: true })
          }

          const { username } = user.data
          await store.dispatch('user/fetchUser', username, { root: true})
          await store.dispatch('setting/fetchActiveUserSettings', {}, { root: true })
          return true
        }
      }else {
        return false
      }
    }
    return false
  }
}