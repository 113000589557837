/**
 * User service implementation
 */
import axios  from 'axios';

export default {
  create(payload){
     return axios.get(`/users`, {
       ...payload
     });
  },
  get(username){
    return axios.get(`/users/username/${username}`)
  }
}