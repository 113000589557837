// require("dotenv").config()
const NODE_ENV = process.env.NODE_ENV

export default {
  ozunu: (path) => {
    return NODE_ENV === 'production' ?  `${process.env.VUE_APP_OZUNU_API_PROD}${path}`: 
    NODE_ENV === 'staging' ? `${process.env.VUE_APP_OZUNU_API_STAGING}${path}`: 
    `${process.env.VUE_APP_OZUNU_API_DEV}${path}`
  },
  hyacinth: (path) => {
    return NODE_ENV === 'production' ?  `${process.env.VUE_APP_HYACINTH_API_PROD}${path}`: 
    NODE_ENV === 'staging' ? `${process.env.VUE_APP_HYACINTH_API_STAGING}${path}`: 
    `${process.env.VUE_APP_HYACINTH_API_DEV}${path}`
  },
  socketConnection: NODE_ENV === 'production' ? process.env.VUE_APP_OZUNU_SOCKET_URI_PROD : 
    NODE_ENV === 'staging' ? process.env.VUE_APP_OZUNU_SOCKET_URI_STAGING :
    process.env.VUE_APP_OZUNU_SOCKET_URI_DEV
};
