// Written By : Nemese Kalubi
// Date: Fri, Oct 1, 2022

import TomtomService from "../../services/tomtom.service"
import tt from "@tomtom-international/web-sdk-maps";

export default {
  namespaced: true,
  state: {
    markers: [],
    geocode: null,
    geoLocation: null,
    routeLayer: null,
    routeDetails: null,
    routeSummary: null,
    eventMarkers: []
  }, 
  getters: {
    selectMarkers(state){
      return state.markers
    },
    selectGeoLocation(state){
      return state.geoLocation
    },
    selectRouteSummary(state){
      return state.routeSummary
    },
    selectRouteDetails(state){
      return state.routeDetails
    }
  },
  mutations: {
    SET_GEOCODE(state, geocode){
      state.geocode = geocode
    },
    SET_MARKERS(state, markers){
      state.markers.push(markers)
    },
    RESET_STATE(state){
      state.geocode = null,
      state.geoLocation = null,
      state.routeLayer = null,
      state.routeDetails = null,
      state.routeSummary = null,
      state.eventMarkers = []
    },
    REMOVE_MARKERS(state){
      for(const marker of state.markers){
        marker.remove()
      }
      state.markers = []
    },
    SET_GEOLOCATION(state, data) {
      state.geoLocation = data
    }, 
    SET_ROUTER_LAYERS(state, layer){
      state.routeLayer = layer
    },
    SET_ROUTE_SUMMARY(state, summary){
      state.routeSummary = summary
    },
    SET_EVENT_MARKERS(state, markers){
      state.eventMarkers = markers
    },
    SET_ROUTE_DETAILS(state, details){
      state.routeDetails = details
    }
  },
  actions: {
    setMarkers({ commit }, marker){
      commit('SET_MARKERS', marker)
    },

    resetState({ commit }){
      commit('RESET_STATE')
      commit('REMOVE_MARKERS')
    },

    removeMarkers({ commit }){
      commit('REMOVE_MARKERS')
    },

    setRouteLayer({ commit }, layer){
      commit('SET_ROUTER_LAYERS', layer)
    },

    setRouteSummary({ commit }, summary){
      commit('SET_ROUTE_SUMMARY', summary)
    },

    setMarkersByEvent({ commit }, { locations, details }){
      if(!locations || !locations.length){
        return 
      }
      commit('REMOVE_MARKERS',)
      commit('SET_ROUTE_DETAILS', details)
      commit('SET_EVENT_MARKERS', locations)
    },

    async searchGeocode({ commit }, payload){
      const response = await TomtomService.searchGeocode(payload)
      if(response && response.data){
        commit('SET_GEOCODE', response.data.geocode)
      }
    },

    async calculateRoute({ commit }, { key, locations }){
      key = key || process.env.VUE_APP_TOMTOM_API_KEY
      const response = await TomtomService.calculateRoute({ key, locations })
      if(response && response.routes){
        const geojson = {
          'id': 'route',
          'type': 'line',
          'source': {
            'type': 'geojson',
            'data': response.toGeoJson(),
          },
          'paint': {
            'line-color': 'blue',
            'line-width': 5
          }
        }
        commit('SET_ROUTE_SUMMARY', response.routes[0].summary)
        commit('SET_ROUTER_LAYERS', geojson)
      }
    }
  }
}