import UserService  from "../../services/user.service";

export default {
  namespaced: true,

  state: {
    activeUser: null,
  },
  getters: {
    selectFirstname(state){
      try{
        const { name } = state.activeUser
        const firstname = name.split(' ')[0];
        return firstname
      }catch(err){
        console.log(err)
      }
    },
    selectCurrentUser(state){
      return state.activeUser
    }
  },
  mutations: {
    setActiveUser( state, user ) {
      state.activeUser = user;
    }
  }, 
  actions: {
    async fetchUser({ commit, dispatch }, username){
      const user = await UserService.get(username);
      commit('setActiveUser', user.data);

      // fetch user profile
      const { _id: id } = user.data
      await dispatch('profile/fetchProfile', id, { root: true })
    }
  }
}
