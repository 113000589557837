import axios from 'axios'

export default {
  list(payload){
    return axios.post('/message/list', { 
      ...payload 
    })
  },
  send (payload){
    return axios.post('/message/send', {
      ...payload
    })
  },
  update(payload){
    return axios.post('/message/update', {
      ...payload
    })
  },
  delete(payload){
    return axios.post('/message/delete', {
      ...payload
    })
  }
}