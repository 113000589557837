// Written By : Nemese Kalubi
// Date: Sat, March 20, 2022

import moment from "moment";

/**
 * @module charts.utility
 */


/**
 * summaryChart
 * @param {Object} local
 * @param {Object} series
 * @param {Object} global,  
 * @param {Object} humidity, 
 * @param {Object} temperature,, 
 * @returns apex chart chart 
 */
export const summaryChart = ({ temperature, humidity, local, global }) => {
  return {
    series: [
      {
        name: "Room Temperature",
        type: "bar",
        data: temperature.data,
      },
      {
        name: "Room Humidity",
        type: "line",
        data: humidity.data,
      },
      {
        name: "Outside Temperature",
        type: "bar",
        data: local.data,
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        foreColor: global.foreColor || "#373d3f",
      },
      theme: {
        palette: global.palette || "palette1",
      },
      stroke: {
        width: [0, 2, 5],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      fill: {
        opacity: [0.85, 1, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: global.labels,
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            return Math.ceil(value);
          },
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function(y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " °";
            }
            return y;
          },
        },
      },
    },
  };
};

/**
 * bar chart
 * @param {JSON} fields
 * @returns {JSON} bar chart object
 */
export const barChart = ({
  id,
  name,
  type,
  feeds,
  limit,
  palette,
  options,
  fieldName,
  foreColor,
}) => {
  return {
    options: {
      chart: {
        id,
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        type,
        height: 250,
        foreColor: foreColor || "#373d3f",
      },
      dataLabels: {
        enabled: false,
      },
      theme: {
        palette: palette || "palette2",
      },
      stroke: {
        width: 0.5,
        //curve: 'smooth',
        // OR provide an array
        curve: ["smooth", "straight", "stepline"],
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        categories: feeds
          .filter((feed) => {
            if (feed[fieldName]) {
              return feed[fieldName];
            }
          })
          .map((feed) => moment(feed.created_at).format("LT"))
          .slice(0, limit),
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            return Math.ceil(value);
          },
        },
      },
    },
    series: [
      {
        name: name,
        data: feeds
          .filter((feed) => {
            if (feed[fieldName]) {
              return feed[fieldName];
            }
          })
          .map((feed) => feed[fieldName])
          .slice(0, limit),
      },
    ],
  };
};

/**
 * apply filters
 * @param {JSON} fields
 * @returns {JSON} filters object
 */
export const applyGlobalFilters = (fields) => {
  const {
    feeds,
    limit,
    fieldName,
    id,
    name,
    foreColor,
    others,
    palette,
    type,
  } = fields;

  return {
    id,
    type,
    name,
    feeds,
    limit,
    palette,
    foreColor,
    fieldName,
    ...others,
  };
};

/**
 * get labels for charts
 * @param {JSON} fields
 * @returns {JSON} filters object
 */
export const getChartLabels = ({ limit, data }) => {
  if (!data || !limit) {
    return;
  }

  return data.map((item) => item.startTime).slice(0, limit);
};

export function spotlightChart({
  categories = [],
  temperature,
  humidity,
  local,
  global = {},
}) {
  return {
    series: [
      {
        name: "Room Temperature",
        data: temperature || [],
      },
      {
        name: "Room Humidity",
        data: humidity || [],
      },
      {
        name: "Outside Temperature",
        data: local || [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },

      chart: {
        id: "spotlightChart",
        toolbar: {
          show: false,
        },
        foreColor: global.foreColor || "#373d3f",
      },

      plotOptions: {
        bar: {
          borderRadius: 5,
        },
      },

      stroke: {
        width: 0.2,
        colors: ["#74fb00"],
        curve: "smooth",
      },

      legend: {
        position: "top",
        horizontalAlign: "right",
      },

      dropShadow: {
        enabled: true,
        top: 0,
        left: 0,
        blur: 3,
        opacity: 0.5,
      },

      theme: {
        // monochrome: {
        //   enabled: true,
        //   shadeTo: "light",
        //   shadeIntensity: 0.65
        // },
        palette: global.palette || "palette1",
      },

      grid: {
        show: true,
        borderColor: global.foreColor || "#D2DDEC",
        strokeDashArray: 3,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 5,
        },
      },

      xaxis: {
        categories,
      },
    },
  };
}

/**
 * topServicesChart
 * @param {Object} series, 
 * @param {Object} global, 
 * @param {Object} categories, 
 * @returns apex chart chart 
 */
export function topServicesChart({ series, categories, global }) {
  return {
    options: {
      dataLabels: {
        enabled: false,
      },

      legend: {
        position: "right",
        fontSize: "15px",
        horizontalAlign: "center",
        itemMargin: {
          horizontal: 5,
          vertical: 5,
        },
      },

      stroke: {
        show: false,
        width: 0,
      },

      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],

      labels: categories,

      chart: {
        height:"250",
        type: "donut",
        foreColor: global.foreColor || "#373d3f",
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Rubik",
                color: "#dfsda",
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                color: undefined,
                offsetY: 5,
                formatter: function(val) {
                  return val;
                },
              },
              total: {
                show: true,
                label: "Total",
                color: global.foreColor || "#373d3f",
                formatter: function(w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },

      theme: {
        // monochrome: {
        //   enabled: true,
        //   shadeTo: "light",
        //   shadeIntensity: 0.65
        // },
        palette: global.palette || "palette1",
      },
    },

    series: series,
  };
}

/**
 * servicesUsageChart
 * @param {Object} series, 
 * @param {Object} global, 
 * @param {Object} categories, 
 * @returns apex chart chart 
 */
export function servicesUsageChart({ series, categories, global }) {
  return {
    series: series,

    options: {
      chart: {
        id: 'ServicesUsageChart',
        type: "area",
        height:"250",
        stacked: false,
        toolbar: {
          show: false,
        },
        foreColor: global.foreColor || "#373d3f",
      },

      dataLabels: {
        enabled: false
      },
  
      stroke: {
        width: 4,
        curve: 'smooth',
      },

      plotOptions: {
        bar: {
          columnWidth: "50%"
        }
      },

      xaxis: {
        categories: categories,
      },

      // yaxis: [{},{
      //   opposite: true
      // }],
      
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: '12px',
          fontFamily: undefined
        },
        onDatasetHover: {
            highlightDataSeries: true,
        },
      },

      legend: {
        // show: false,
        // position: 'top',
        horizontalAlign: "left",
        // offsetX: 40
      },

      theme: {
        palette: global.palette || "palette1",
      },

      grid: {
        show: true,
        borderColor: global.foreColor || "#D2DDEC",
        strokeDashArray: 2,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 5,
        },
      },

    }
  };
}