// Written By : Nemese Kalubi
// Date: Fri, Nov 4, 2022

import lodash from "lodash"
import moment from "moment"
import AgentService from '../../services/agent.service'

/**
 * @module agent.module
 */

export default {
  namespaced: true,
  state: {
    agent: null,
    lastPage: 1,
    allLoaded: false,
    wasUpdated: false,
    chatTimeline: {},
    isLoadingMore: false,
    agentResponses: [],
  },
  getters: {
    selectAgent(state){
      return state.agent
    },
    selectLastPage(state){
      return state.lastPage
    },
    selectAllLoaded(state){
      return state.allLoaded
    },
    selectWasUpdated(state, event){
      return state.wasUpdated
    },
    selectChatTimeline(state){
      return state.chatTimeline
    },
    selectIsLoadingMore(state){
      return state.isLoadingMore
    },
    selectAgentResponses(state){
      return state.agentResponses
    },
  },
  mutations: {
    SET_AGENT(state, agent){
      state.agent = agent
    },
    SET_LAST_PAGE(state, lastPage){
      state.lastPage = lastPage
    },
    SET_ALL_LOADED(state, allLoaded){
      state.allLoaded = allLoaded
    },
    SET_WAS_UPDATED(state, updated){
      state.wasUpdated = updated
    },
    SET_CHAT_TIMELINE(state, timeline){
      state.chatTimeline = timeline
    },
    SET_AGENT_RESPONSES(state, response){
      state.agentResponses = response
    },
    SET_IS_LOADING_MORE(state, isLoading){
      state.isLoadingMore = isLoading
    }
  },
  actions: {
    setIsLoadingMore({ commit }, status){
      commit('SET_IS_LOADING_MORE', status)
    },
    setAllLoaded({ commit }, status){
      commit('SET_ALL_LOADED', status)
    },
    async fetchAgent({ commit }, payload){
      const response = await AgentService.getAgent()
      if(response && response.data){
        commit('SET_AGENT', response.data.agent)
      }
    },
    handleAgentResponseChatEvent({ commit, getters, dispatch }, chat){
      if(!chat) return
      const timeline = getters.selectChatTimeline
      const timestamp = moment(chat.createdAt).format('LL')
      if(!timeline[timestamp]){
        timeline[timestamp] = {
          chat: [chat]
        }
      }
      else {
        timeline[timestamp] = {
          chat: [...timeline[timestamp].chat, chat]
        }
      }
      console.log({ chat })
      commit('SET_WAS_UPDATED', true)
      commit('SET_CHAT_TIMELINE', timeline)
    },
    async fetchAgentResponses({ commit, dispatch }, payload){
      const response = await AgentService.getResponses(payload)
      if(response && response.data){
        dispatch('generatteChatTimeline', response.data.responses)
        commit('SET_AGENT_RESPONSES', response.data.responses)
      }
    },
    async fetchMoreAgentResponses({ commit, getters, dispatch }, payload){
      const lastPage = getters.selectLastPage
      const agentResponses = getters.selectAgentResponses

      const limit = 20
      const skip = (lastPage) * 20

      const response = await AgentService.getResponses({ limit, skip})
      if(response && response.data && response.data.responses.length){
        const data = [...agentResponses, ...response.data.responses]
        const uniq = lodash.uniqBy(data, function(value){
          return value._id
        })
        if(uniq.length){
          dispatch('generatteChatTimeline', uniq)
          commit('SET_AGENT_RESPONSES', uniq)
          commit('SET_LAST_PAGE', lastPage + 1)
        }
      }
      else{
        commit('SET_ALL_LOADED', true)
      }
    },
    generatteChatTimeline({ commit }, chatData){
      if(!chatData || !chatData.length){
        commit('SET_CHAT_TIMELINE', [])
      }

      if(!Array.isArray(chatData)){
        throw new Error('Data must be of array type')
      }

      const sortedTimeline = {}

      chatData.forEach(chat => {
        const timestamp = moment(chat.createdAt).format('LL')
        if(!sortedTimeline[timestamp]){
          sortedTimeline[timestamp] = {
            chat: [chat]
          }
        }
        else{
          sortedTimeline[timestamp] = {
            chat: [...sortedTimeline[timestamp].chat, chat]
          }
        }
      })
      commit('SET_CHAT_TIMELINE', sortedTimeline)
    }
  }
}