<template>
  <div class="px-3">
    <div class="accordion" role="tablist">
      <div no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <span href="javascript:void(0);" block v-b-toggle.agent-accordion variant="info">Hide details</span>
        </b-card-header>
        <b-collapse id="agent-accordion" visible accordion="my-accordion" role="tabpanel">
          <b-card-body class="py-2">
            <div>
              <div class="h6 mt-2 mb-2">
                <i class="bi bi-geo-alt"></i> {{ key }}
                <hr>
              </div>
              <b-card-text> Name: <code>Skylar</code></b-card-text>
              <b-card-text> Country: <code>United State</code></b-card-text>
              <b-card-text> Municipality:  <code>Salt Lake City</code></b-card-text>
              <b-card-text> Free Form Address:  <code>3692 St Andrews Dr Eagle Mountain Utah</code></b-card-text>
            </div>
          </b-card-body>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AgentDetails'
  }
</script>

<style lang="scss" scoped>

</style>