// Written By : Nemese Kalubi
// Date: Fri Apr 15, 2022

import store from '../../store'

/**
 * @module displayRouteInfo.event
 */

/**
 * display routeInfo event
 * @param {JSON} data socket payload
 * @return none
 */
export default async (data) => {
  // dispatch tomtom calculateRoute api
  store.dispatch('tomtom/resetState', {}, { root: true })
  store.dispatch('tomtom/setMarkersByEvent',{...data}, { root: true })
  await store.dispatch('tomtom/calculateRoute',{...data}, { root: true })
}