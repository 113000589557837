// Written by: Nemese Kalubi
// Date: Sat, Jan 1, 2022

import MessageService from'../../services/message.service'
import ConversationService from '../../services/conversation.service'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    displayMode: 'desktop',
    conversations: [],
    currentSelectedPage: 'Inbox',
    currentConversation: null,
  },
  getters: {
    selectIsLoading(state){
      return state.isLoading
    },
    selectDisplayMode(state){
      return state.displayMode
    },
    selectConversations(state){
      return state.conversations
    },
    selecConversationTitle(state){
      const restrictedNumber = '+14156884656'
      const conversation = state.currentConversation
      
      if(!conversation){
        return 'Unknown party'
      }

      if(conversation && !conversation.parties){
        return 'Unknown party'
      }
      else{
        const { from, person } = conversation.parties

        if(!person){
          return from && from !== restrictedNumber ? from : 'Unknown party'
        }
        return person.name || person.phone
      }
    },
    selectCurrentSelectedPage(state){
      return state.currentSelectedPage
    },
    selectCurrentConversation(state){
      return state.currentConversation
    },
  },
  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_CONVERSATIONS(state, conversations) {
      state.conversations = conversations
    },
    SET_SORTED_CONVERSIONS(state, conversations){
      state.conversations = conversations
    },
    SET_CURRENT_CONVERSATION(state, conversation) {
      state.currentConversation = conversation
    },
    SET_CURRENT_SELECTED_PAGE(state, page){
      state.currentSelectedPage = page
    },
    SET_CONVERSATION_DISPLAY_MODE(state, displayMode){
      state.displayMode = displayMode
    }
  },
  actions: {
    async setCurrentSelectedPage({ commit, dispatch }, page){
      commit('SET_CURRENT_SELECTED_PAGE', page)
      commit('SET_CURRENT_CONVERSATION', null)

      if(page === 'ARCHIVED'){
        const payload = {
          others: {
            archived: {$eq: true }
          }
        }
        dispatch('fetchConversations', payload)
      }

      if(page === 'INBOX'){
        dispatch('fetchConversations')
      }

      if(page === 'UNREAD'){
        const payload = {
          others: {
            hasNewMessages: {$eq: true }
          }
        }
        dispatch('fetchConversations', payload)
      }
    },
    setConversationDisplayMode({commit}, windowSize){
      let displayMode = ''
      // window size is consider mobile
      if(windowSize <= 990){
        displayMode = 'mobile'
      }   
      if(windowSize > 990){
        displayMode = 'desktop'
      }
      commit('SET_CONVERSATION_DISPLAY_MODE', displayMode)
    },
    async fetchConversations({ commit, rootState }, payload){
      commit('SET_IS_LOADING', true )

      try{
        const { _id: userId } = rootState.user.activeUser

        payload = payload !== undefined ? { 
          filters: {
            ...payload, 
            userIds: [userId] 
          }
        } : 
        {
          filters: {
            ...payload,
            userIds: [userId],
            others: {
              archived: {$ne: true }
            }
          }
        }
        
        const response = await ConversationService.list(payload)

        if(response && response.data){
          const conversations = response.data
          const conversationIds = []
          
          if(conversations && conversations.length){
            conversations.forEach(conversation => conversationIds.push(conversation._id))
          }
          
          commit('SET_CONVERSATIONS', conversations)
        }
        commit('SET_IS_LOADING', false )
      }catch(error){
        console.error(error)
        commit('SET_IS_LOADING', true )
      }
    },
    async searchConversations({ rootGetters, dispatch }, searchString ) {
      const { _id: userId } = rootGetters['user/selectCurrentUser']
      
      const messagePayload = {
        filters: {
          body: searchString,
          userIds: [ userId ],
        }
      }

      const response = await MessageService.list(messagePayload)

      if(response && response.data){
        const conversationIds = response.data.map(message => message.conversation)

        await dispatch('fetchConversations', { conversationIds })
      }
    },
    async markConversationAsRead({dispatch}, conversation){
      try{
        const payload = { 
          conversationId: conversation._id 
        }
        const response = await ConversationService.markRead(payload)

        if(response && response.status === 200){
          await dispatch('fetchConversations')
        }
      }
      catch (err) {
        console.log(err)
      }
    },
    async markConversationAsArchived({ dispatch, getters, commit }, { conversationId }){
      const currentPage = getters['selectCurrentSelectedPage']

      if(!conversationId){
        return
      }

      let response
      
      if(currentPage === 'ARCHIVED'){
        response = await ConversationService.marchArchived({ conversationId, status: false})
        response.data.message = 'Conversation was successfully restored'
        commit('SET_CURRENT_SELECTED_PAGE', 'INBOX')
      }
      else if (currentPage !== 'ARCHIVED'){
        response = await ConversationService.marchArchived({ conversationId, status: true })
        response.data.message = 'Conversation was successfully archived'
      }

      if(response && response.data ){
        await dispatch('fetchConversations')
        return response.data
      }
    },
    async handleSelectedConversation({ commit, dispatch }, conversation ){
      if(!conversation) return

      commit('SET_CURRENT_CONVERSATION', conversation)

      const payload = { conversationId: conversation._id }
      await dispatch('message/fetchSelectedConvoMessages', payload, { root: true })
    },
  }
}