
/**
 * @module statusLoader.module
 */

export default {
  namespaced: true,

  state: {
    loaders: []
  },
  getters: {
    selectLoaders(state){
      return state.loaders
    },
    selectLoaderByName: state => loaderName => {
      return state.loaders.find(l => l === loaderName)
    }
  },
  mutations: {
    ADD_TO_LOADERS(state, loader){
      state.loaders.push(loader)
    },
    REMOVE_FROM_LOADERS(state, loaderName){
      state.loaders = state.loaders.filter(l => l !== loaderName)
    }
  },
  actions: {
    addToLoaders({ commit }, loader){
      commit('ADD_TO_LOADERS', loader)
    },
    removeFromLoaders({ commit }, loaderName){
      commit('REMOVE_FROM_LOADERS', loaderName)
    }
  }
}