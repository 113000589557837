import router from '@/router'
import { createStore } from 'vuex'
import user from './modules/user.module'
import auth from './modules/auth.module'
import agent from './modules/agent.module'
import email from './modules/email.module'
import action from './modules/action.module'
import intent from './modules/intent.module'
import avatar from './modules/avatar.module'
import report from './modules/report.module'
import metrics from './modules/metrics.module'
import loaders from './modules/loader.module'
import tomtom from './modules/tomtom.module'
import setting from './modules/setting.module'
import message from './modules/message.module'
import activity from './modules/activity.module'
import profile from './modules/profile.module'
import hyacinth from './modules/hyacinth.module'
import websocket from './modules/websocket.module'
import controlCenter from './modules/control-center.module'
import conversation from './modules/conversation.module'
import passwordRecovery from './modules/password-recovery.module'
import speechRecognition from './modules/speech-recognition.module'

const defaultTitle = () => 'Dashboard'

const store = createStore({
  state() {
    return {
      pageTitle: defaultTitle()
    }
  },
  getters: {
    selectPageTitle(state) {
      return state.pageTitle
    }
  },
  mutations: {
    setPageTitle: (state, title )=> state.pageTitle = title
  },
  actions: {
    async navigateTo({ commit }, path ){
      if(path === 'Settings'){
        return 
      }
      commit('setPageTitle', path)
      await router.push({ name: path })
    },
    async changePageTitle({ commit }, title){
      commit('setPageTitle', title)
    },
    async navigateToOption({ commit, dispatch }, option){
      await dispatch('navigateTo', option)
    }
  },

  modules: {
    user,
    email,
    agent,
    action,
    intent,
    avatar,
    report,
    tomtom,
    loaders,
    metrics,
    setting,
    profile,
    message,
    activity,
    hyacinth,
    websocket,
    conversation,
    controlCenter,
    authentication: auth,
    passwordRecovery: passwordRecovery,
    speechRecognition: speechRecognition
  },
})

export default store