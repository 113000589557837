import IntentService from "../../services/intent.service";
import ReportService from "../../services/report.service"
import { v4 as uuidv4 } from "uuid";
import REPORT from '../../../lib/report-creation.util'
import { applyGlobalFilters, barChart, getChartLabels, summaryChart } from '../../../lib/charts'

export default {
  namespaced: true,

  state: {
    fans: [],
    limit: 10,
    charts: [],
    widgets: [],
    reports: null,
    lights: [],
    creation: {},
    humidity: [],
    temperature: [],
    currentChart: null,
    creationStep: 'SELECT_TYPE',
    isReportCreating: false,
  },

  getters: {
    selectFans: (state) => {
      return state.fans
    },
    selectLights: (state) => {
      return state.lights
    },
    selectCharts: (state) => {
      return state.charts
    },
    selectWidgets: (state) => {
      if(state.reports && state.reports.widgets){
        return state.reports.widgets
      }
      else {
        return []
      }
    },
    selectReports: (state) => {
      return state.reports
    },
    selectCreation: (state) => {
      return state.creation
    },
    selectHumidity: (state) => {
      return state.humidity
    },
    selectWidgetsList(state){
      return state.widgets
    },
    selectIntentCount(state){
      if(state.reports){
        const { reports: { intent: count } = {}} = state
        return count
      }
      return null;
    },
    selectTemperature: (state) => {
      return state.temperature
    },
    selectCurrentChart: (state) => {
      return state.currentChart
    },
    selectCreationStep: (state) => {
      return state.creationStep
    },
    selectReportsByKind: (state) => kind => {
      switch(kind){
        case 'MESSAGE_COUNT':
          return state.reports.message ? state.reports.message.messageCount : null
        case 'CONNECTIONS_COUNT':
          return state.reports.connection ? state.reports.connection.connectionsCount : null
        case 'CONVERSATIONS_COUNT':
          return state.reports.conversation ? state.reports.conversation.conversationsCount : null
        case 'INTENT_PHRASES_COUNT':
          return state.reports.intent ? state.reports.intent.count : null
        default: 
         return null
      }
    },
    selectIsReportCreating(state){
      return state.isReportCreating
    },
  },

  mutations: {
    SET_FANS: (state, fans) => {
      state.fans = fans
    },
    SET_LIGHTS: (state, Lights) => {
      state.lights = Lights
    },
    SET_HUMIDITY: (state, humidity) => {
      state.humidity = humidity
    },
    SET_CHART_DATA: (state, chart) => {
      const index = state.charts
      .findIndex(ch => ch.options.chart.id === chart.options.chart.id )

      if(index === -1) {
        state.charts.push(chart)
      }
      else if(index > -1){
        state.charts[index] = chart
      }
    },
    SET_WIDGET_LIST: (state, widgets) => {
      state.widgets = widgets
    },
    SET_TEMPERATURE: (state, temperature) => {
      state.temperature = temperature
    },
    SET_USER_REPORTS: (state, reports) => {
      state.reports = { ...state.reports, ...reports }
      state.widgets = state.reports.widgets
      const colors = [
        //"text-info",
        "text-success",
        "text-warning",
        "text-danger",
        "text-primary",
      ]
      const widgets = state.widgets.map(widget => {
        const randomNumber = Math.floor(Math.random() * colors.length)
        const randColor = colors[randomNumber]
        return {
          ...widget,
          icon: widget.icon.includes('text-muted') ? widget.icon.replace(/text-muted/, randColor) : `${widget.icon} ${randColor}`
        }
      })
      state.reports.widgets = widgets
    },
    SET_SELECTED_CHART: (state, selectedChart) => {
      state.currentChart = selectedChart
    },
    SET_IS_REPORT_CREATING: (state, status) => {
      state.isReportCreating = status
    },
    SET_REPORT_CREATION_STEP: (state, step) => {
      state.creationStep = step
    },
    SET_REPORT_CREATION_FIELD: (state, field) => {
      state.creation = {
        ...state.creation,
        ...field
      }
    },
  },

  actions: {
    setSelectedChart({ commit}, chart) {
      commit('SET_SELECTED_CHART', chart)
    },

    setReportCreationField({ commit}, field) {
      commit('SET_REPORT_CREATION_FIELD', field)
    },

    setReportCreationStep({ commit}, step) {
      commit('SET_REPORT_CREATION_STEP', step)
    },

    searchWidget({ commit, getters }, searchString ){
      const reports = getters['selectReports']

      if(searchString && searchString.length){
        const regex = RegExp(searchString, 'i')
        const results = reports.widgets
        .filter(w => {
          if(w.displayName.match(regex) || w.type.match(regex)){
            return true
          }
        })
        commit('SET_WIDGET_LIST', results)
      }
      else {
        commit('SET_WIDGET_LIST', reports.widgets)
      }
    },

    async loadUserReports({ commit, rootGetters },){
      const user = rootGetters['user/selectCurrentUser']

      const response = await ReportService.list({ userId: user._id })

      if(response && response.data){
        commit('SET_USER_REPORTS', response.data)
      }
    },

    async createNewReport({ commit, rootGetters }, payload) {
      commit('SET_IS_REPORT_CREATING', true)
      const user = rootGetters['user/selectCurrentUser']

      if(payload && payload.type === REPORT.TYPES.WIDGET){
        await ReportService.updateReport({
          userId: user._id,
          payload: payload,
        })
      }

      commit('SET_IS_REPORT_CREATING', false)
    },

    async fetchIntentCount({ commit, rootGetters }) {
      const user = rootGetters['user/selectCurrentUser']
      const response = await IntentService.count({
        userId: user._id,
      })

      if(response && response.data){
        commit('SET_USER_REPORTS', { intent: response.data })
      }
    },

    async fetchMessageCount({ commit, rootGetters }){
      const user = rootGetters['user/selectCurrentUser']

      const response = await ReportService.messageCount({
        userId: user._id,
      })

      if(response && response.data){
        commit('SET_USER_REPORTS', { message: response.data })
      }
    },

    async fetchConnectionCount({ commit, rootGetters }){
      const user = rootGetters['user/selectCurrentUser']

      const response = await ReportService.connectionCount({
        userId: user._id,
      })

      if(response && response.data){
        commit('SET_USER_REPORTS', { connection: response.data })
      }
    },

    async updateWidgetStatusById ({ dispatch }, payload){
      const response = await ReportService.updateWidget(payload)
      if(response && response.data.success){
        await dispatch('loadUserReports')
      }
    },

    async fetchConversationCount({ commit, rootGetters }){
      const user = rootGetters['user/selectCurrentUser']

      const response = await ReportService.conversationCount({
        userId: user._id,
      })

      if(response && response.data){
        commit('SET_USER_REPORTS', { conversation: response.data })
      }
    },

    async fetchFansReport({ commit}){
      const payload = {
        filters: {
          limit: 100
        }
      }
      const response = await ReportService.fans(payload)

      if(response && response.data){
        commit('SET_FANS', response.data)
      }
    },

    async applyFilters({dispatch}, { fields, chart }){
      const { name } = chart
      switch(name){
        case 'TEMPERATURE_CHART':
          await dispatch('fetchTemperatureReport', {...fields})
          break
        case 'HUMIDITY_CHART':
          await dispatch('fetchHumidityReport', {...fields})
          break
        case 'SUMMARY_CHART':
          await dispatch('fetchSummaryChartReport', {...fields})
          break
        case 'SPOTLIGHT_CHART':
          await dispatch('metrics/fetchSpotlightMetrics', {...fields}, { root: true })
          break
        default:
          break
      }
    },

    async fetchLightsReport({ commit}){
      const payload = {
        filters: {
          limit: 100
        }
      }
      const response = await ReportService.light(payload)

      if(response && response.data){
        commit('SET_LIGHTS', response.data)
      }
    },

    async fetchHumidityReport({ commit, rootGetters }, { limit, dateRange, conversionUnit, palette }){
      const application = rootGetters['setting/selectApplicationSettings']

      const payload = {
        filters: {
          limit: 100,
          dateRange: dateRange && dateRange.length ? dateRange : null,
          conversionUnit: conversionUnit ? conversionUnit : 'fahrenheit'
        }
      }

      const response = await ReportService.humidity(payload)

      if(response && response.data){
        const { feeds } = response.data

        if(feeds){

          const options = applyGlobalFilters({
            name: 'Humidity',
            id: 'humidity-chart',
            type: 'area',
            feeds, 
            limit: limit ? limit : 10,
            palette: palette || application.chart.palette,
            foreColor: application.theme === 'light' ? 'null' : '#b4b6b8',
            fieldName: 'field2',
          })

          const chart = {
            id: uuidv4(),
            name: 'HUMIDITY_CHART',
            ...barChart({ ...options })
          }
          commit('SET_CHART_DATA', chart)
        }
      }
    },

    async fetchTemperatureReport({ commit, rootGetters }, { limit, dateRange, conversionUnit, palette } ){
      const application = rootGetters['setting/selectApplicationSettings']

      const payload = {
        filters: {
          limit: 100,
          dateRange: dateRange && dateRange.length ? dateRange : null,
          conversionUnit: conversionUnit ? conversionUnit : 'fahrenheit'
        }
      }
      const response = await ReportService.temperature(payload)

      if(response && response.data){
        const { feeds } = response.data

        if(feeds){

          const options = applyGlobalFilters({
            name: 'Temperature',
            id: 'temperature-chart',
            type: 'bar',
            feeds,
            limit: limit ? limit : 7,
            palette: palette || application.chart.palette,
            foreColor: application.theme === 'light' ? 'null' : '#b4b6b8',
            fieldName: 'field1',
          })

          const chart = {
            id: uuidv4(),
            name: 'TEMPERATURE_CHART',
            ...barChart({ ...options })
          }
          commit('SET_CHART_DATA', chart)
        }
      }
    },

    async fetchSummaryChartReport({ commit, rootGetters },  { limit, dateRange, conversionUnit, palette }) {
      const application = rootGetters['setting/selectApplicationSettings']

      const payload = {
        filters: {
          limit: 100,
          dateRange: dateRange && dateRange.length ? dateRange : null,
          conversionUnit: conversionUnit ? conversionUnit : 'fahrenheit'
        }
      }

      const responses = await Promise.all([
        ReportService.humidity(payload),
        ReportService.temperature(payload),
        ReportService.LocalTemperature(payload)
      ])

      const humidityResponse = responses[0].data
      const temperatureResponse = responses[1].data
      const localTemperatureResponse = responses[2].data
  
      const options = {
        local: {
          data: localTemperatureResponse.filter(item => item.temperature)
          .map(item => item.temperature)
          .slice(0, limit)
        },
        global: {
          labels: getChartLabels({
            data: localTemperatureResponse,
            limit: limit,
          }),
          palette: palette || application.chart.palette, 
          foreColor: application.theme === 'light' ? 'null' : '#b4b6b8',
        },
        humidity: {
          data: humidityResponse.feeds.filter(feed => feed.field2)
          .map(feed => feed.field2)
          .slice(0, limit)
        }
        ,
        temperature: {
          data: temperatureResponse.feeds.filter(feed => feed.field1 )
          .map(feed => feed.field1)
          .slice(0, limit)
        }
      }

      const chart = {
        id: uuidv4(),
        name: 'SUMMARY_CHART',
        ...summaryChart({ ...options })
      }

      commit('SET_CHART_DATA', chart)
    },

    async fetchReportByKind({ dispatch }, kind){
      switch(kind){
        case 'MESSAGE_COUNT':
          await dispatch('fetchMessageCount')
          break

        case 'CONNECTIONS_COUNT':
          await dispatch('fetchConnectionCount')
          break

        case 'INTENT_PHRASES_COUNT':
          await dispatch('fetchIntentCount')
          break

        case 'CONVERSATIONS_COUNT':
          await dispatch('fetchConversationCount')
          break
        
        default:
          break
      }
    }
  }
}
