// Written By : Nemese Kalubi
// Date: Wed, Sep 28, 2022


const supportedLanguages = [
  {
    "language": "BG",
    "name": "Bulgarian",
    "supports_formality": false
  },
  {
    "language": "CS",
    "name": "Czech",
    "supports_formality": false
  },
  {
    "language": "DA",
    "name": "Danish",
    "supports_formality": false
  },
  {
    "language": "DE",
    "name": "German",
    "supports_formality": true
  },
  {
    "language": "EL",
    "name": "Greek",
    "supports_formality": false
  },
  {
    "language": "EN-GB",
    "name": "English (British)",
    "supports_formality": false
  },
  {
    "language": "EN-US",
    "name": "English (American)",
    "supports_formality": false
  },
  {
    "language": "ES",
    "name": "Spanish",
    "supports_formality": true
  },
  {
    "language": "ET",
    "name": "Estonian",
    "supports_formality": false
  },
  {
    "language": "FI",
    "name": "Finnish",
    "supports_formality": false
  },
  {
    "language": "FR",
    "name": "French",
    "supports_formality": true
  },
  {
    "language": "HU",
    "name": "Hungarian",
    "supports_formality": false
  },
  {
    "language": "ID",
    "name": "Indonesian",
    "supports_formality": false
  },
  {
    "language": "IT",
    "name": "Italian",
    "supports_formality": true
  },
  {
    "language": "JA",
    "name": "Japanese",
    "supports_formality": false
  },
  {
    "language": "LT",
    "name": "Lithuanian",
    "supports_formality": false
  },
  {
    "language": "LV",
    "name": "Latvian",
    "supports_formality": false
  },
  {
    "language": "NL",
    "name": "Dutch",
    "supports_formality": true
  },
  {
    "language": "PL",
    "name": "Polish",
    "supports_formality": true
  },
  {
    "language": "PT-BR",
    "name": "Portuguese (Brazilian)",
    "supports_formality": true
  },
  {
    "language": "PT-PT",
    "name": "Portuguese (European)",
    "supports_formality": true
  },
  {
    "language": "RO",
    "name": "Romanian",
    "supports_formality": false
  },
  {
    "language": "RU",
    "name": "Russian",
    "supports_formality": true
  },
  {
    "language": "SK",
    "name": "Slovak",
    "supports_formality": false
  },
  {
    "language": "SL",
    "name": "Slovenian",
    "supports_formality": false
  },
  {
    "language": "SV",
    "name": "Swedish",
    "supports_formality": false
  },
  {
    "language": "TR",
    "name": "Turkish",
    "supports_formality": false
  },
  {
    "language": "UK",
    "name": "Ukrainian",
    "supports_formality": false
  },
  {
    "language": "ZH",
    "name": "Chinese (simplified)",
    "supports_formality": false
  }
]

export default {
  supportedLanguages
}
