/**
 * Avatar service implementation
 */
import axios from "axios";

export default {
  upload(payload){
    const { avatar: file, user: userId} = payload
    const formData = new FormData()
    formData.append('user', userId)
    formData.append('avatar', file, file.name)

    return axios.post('/avatar/upload', formData)
  },
  update(payload) {
    const { avatar: file, user: userId } = payload;
    const formData = new FormData();
    formData.append("user", userId);
    formData.append("avatar", file, file.name);
    return axios.put('/avatar/update', formData);
  },
  get(user){
    return axios.get(`/avatar/${user}`)
  }
};
