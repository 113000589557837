<template>
  <div class="terminal px-2">
    <TerminalContents/>
  </div>
</template>

<script>
import TerminalContents from './TerminalContents.vue'
  export default {
    name: 'TerminalPage',
    components: {
      TerminalContents
    }
  }
</script>

<style lang="scss" scoped>

</style>