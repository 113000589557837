/**
 * Conversation service implementations
 */
import axios from "axios";

export default {
  list(payload) {
    return axios.post('/conversation/list', {
      ...payload
    })
  }, 
  markRead(payload) {    
    const { conversationId } = payload
    return axios.put(`/conversation/mark-read/${conversationId}`)
  },
  marchArchived(payload) {
    const { conversationId, ...rest } = payload
    return axios.put(`/conversation/mark-archived/${conversationId}`, rest)
  }
}
