<template>
  <div>
      <ion-avatar @click="openAlert">
        <ion-img  :title="avatarTitle" :src="avatar"></ion-img>
      </ion-avatar>
      <div style="display: none">
        <input ref="select_avatar" type="file" @change="handleFileUpload">
      </div>
  </div>
</template>

<script>
import {
  IonAvatar, IonImg,
} from "@ionic/vue";
import { createNamespacedHelpers } from "vuex";
import ALERT from '../../../../lib/custom-alert'
import toaster from '../../../../lib/toaster'
const AvatarModule = createNamespacedHelpers('avatar')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'Avatar',
  components: {
    IonAvatar, 
    IonImg
  }, 
  data() {
    return {
      avatarImage: {
        type: String,
      },
    }
  },
  computed: {
    ...UserModule.mapGetters(['selectCurrentUser']),
    ...AvatarModule.mapGetters(['selectAvatar', 'selectDefaultAvatar']),
    currentUser(){
      return this.selectCurrentUser
    },
    avatarTitle(){
      return this.avatarImage ? 'click to change avatar' : 'upload avatar image'
    },
    avatar(){
      return this.avatarImage ? this.avatarImage : this.defaultAvatar
    }
  },
  methods:{
    ...AvatarModule.mapActions(['fetchAvatar', 'uploadImage', 'updateAvatar']),
    
    async loadAvatar(){
      await this.fetchAvatar(this.currentUser._id)
      if(await this.selectAvatar){
        this.avatarImage = await this.selectAvatar
      }else{
        this.avatarImage = this.selectDefaultAvatar
      }
    },
    async uploadAvatar(){
      await this.$refs.select_avatar.click()
    },
    async handleFileUpload(){
      const { _id: userId } = await this.selectCurrentUser
      const file = this.$refs.select_avatar.files[0]
      const payload ={
        user: userId,
        avatar: file
      }
      // updload new avatar image or update the existing one
      await this.uploadImage(payload)

      if(await this.selectAvatar){
        this.avatarImage = await this.selectAvatar
        toaster.info({
          message: 'Avatar updated',
          position: 'top',
        })
        this.loadAvatar();
      }
    },
    openAlert(){
      ALERT.presentAlertConfirm({
        header: this.avatarImage ? 'Change avatar image?': 'Upload avatar image',
        message: 'Press okay if you would like to continue',
        // add a callback function
        callback: this.uploadAvatar
      })
    },

  },
  mounted(){
    this.loadAvatar()
  }
}
</script>
<style scoped>
ion-avatar{
  height: 100px;
  width: 100px;
  cursor: pointer;
  margin: auto
}
</style>