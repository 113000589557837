import AvatarService from "../../services/avatar.service";

export default {
  namespaced: true,

  state: {
    avatar: null,
    defaultAvatar: 'https://i.pinimg.com/originals/9e/57/8b/9e578b47678afbfd98ea369302875f55.png'
  },
  getters: {
    avatar(state){
      return state.avatar !== null ? state.avatar : state.defaultAvatar
    },
    selectAvatar(state) {
      return state.avatar
    },
    selectDefaultAvatar(state){
      return state.defaultAvatar
    }
  },
  mutations: {
    setAvatar(state, avatar) {
      state.avatar = avatar;
    },
  },
  actions: {
    async uploadImage({ commit, state, dispatch}, img) {
      try {
        if(state.avatar){
          await dispatch('avatar/updateAvatar', img, {root: true})
        }else{
          const avatar = await AvatarService.upload(img);
          if (avatar && avatar.data) {
            await commit("setAvatar", avatar.data.link);
          }
        }
      } catch (err) {
        // ignore
      }
    },
    async fetchAvatar({ commit }, userId) {
      try {
        const avatar = await AvatarService.get(userId);
        if (avatar && avatar.data) {
          await commit("setAvatar", avatar.data.link);
        }
      } catch (err) {
        // ignore
      }
    },
    async updateAvatar({ commit }, payload) {
      try {
        const avatar = await AvatarService.update(payload);
        if (avatar && avatar.data) {
          commit("setAvatar", avatar.data.link);
        }
      } catch (err) {
        // ignore
      }
    },
  },
};
