// Written By : Nemese Kalubi
// Date: Thu, Aug 25, 2022

import store from '../../src/store'
import deepL from '../../lib/deepl'
import ToasterService from '../../lib/toaster'

/**
 * @module SpeechRecognition.client
 * ***********************************************************
 * This file contains class declaration for speech recognition
 * ***********************************************************
 */

// ***************** private members variables **************************
const SYNTHTHESIS = window.speechSynthesis || null
const RECOGNITION = new window.webkitSpeechRecognition() || null
const SPEECH_ULTERANCE = new SpeechSynthesisUtterance() || null
const OLD_ULTERANCE = {}
let IS_AGENT_SPEAKING = false
let TEXT_TO_TRANSLATE;

// ***************** public variables and methods ***********************

export default {
  speak,
  isSpeaking,
  initialize,
  getVoiceList,
  setUlteranceField: setUlteranceField,
  setRecognitionField: setRecognitionField,
  stopRecognition,
  startRecognition,
  speakTranslationText,
}


/**
 * initialize speech recognition
 * @param {Object} options agent options
 * @returns {Void} None
 */
export function initialize({ speechRecognition } = null){
  try{
    if(!speechRecognition){
      return
    }
    if(!SYNTHTHESIS){
      throw new Error('SpeechSynthesis not available.')
    }
    if(!RECOGNITION){
      throw new Error('Speech Recognition not available.')
    }
    if(!SPEECH_ULTERANCE){
      throw new Error('Speech Ulterance not available.')
    }

    // initialize voice, rate, lang, and pitch
    const voiceObject = guessAgentVoice(speechRecognition)
    Object.getOwnPropertyNames(voiceObject).forEach(prop => {
      SPEECH_ULTERANCE[prop] = parseInt(voiceObject[prop]) ?
      parseInt(voiceObject[prop]) : voiceObject[prop]
    })
    const { recording } = speechRecognition
    Object.getOwnPropertyNames(recording).forEach(prop => {
      RECOGNITION[prop] = parseInt(voiceObject[prop]) ?
      parseInt(voiceObject[prop]) : voiceObject[prop]
    })
  }
  catch(err){
    ToasterService.error({
      message: 'Failed to Initialize Speech Recognition Service: ' + err.message,
      position: 'top',
      duration: 5000 // 5 seconds
    })
  }
}

/**
 * speak
 * @param {String} text spoken text
 * @returns {Void} None
 */
export function speak(text){
  if(!text || !text.length){
    return
  }
  try{
    if(SYNTHTHESIS && !SYNTHTHESIS.speaking){
      SPEECH_ULTERANCE.text = text
      SYNTHTHESIS.speak(SPEECH_ULTERANCE)
    }
  }
  catch(err){
    ToasterService.error({
      message: 'The agent is encountering an error while speaking, please try again later. Error: ' + err.message,
      position: 'top'
    })
  }
}


/**
 * isSpeaking
 * @returns {Boolean} agent speaking status
 */
export function isSpeaking(){
  try{
    if(!SYNTHTHESIS){
      throw new Error('SpeechSynthesis not available.')
    }
    return SYNTHTHESIS.speaking
  }
  catch(err){
    ToasterService.error({
      message: 'Error retrieving agen speaking status',
      position: 'top'
    })
  }
}


export function startRecognition(){
  try {
    RECOGNITION.start()
  }
  catch (err) {
    ToasterService.error({
      message: 'Failed to start recognition. Error: ' + err.message,
      position: 'top'
    })
  }
}

export function stopRecognition(){
  try {
    RECOGNITION.stop()
  }
  catch (err) {
    ToasterService.error({
      message: 'Failed to stop recognition. ' + err.message,
      position: 'top',
    })
  }
}


/**
 * guess the agent voice to use
 * @param {Object} options.speechRecognition 
 * @returns {Object} obect containing voice
 */
function guessAgentVoice(speechRecognition){
  const { lang, rate, gender, pitch, volume } = speechRecognition.voice 
  const originalList = getVoiceList()
  const matchingLangList = originalList.filter(voice => voice.lang === lang)
  let voiceList = []
  
  if(matchingLangList && matchingLangList.length){
    voiceList = matchingLangList.filter(voice => {
      const regex = new RegExp('\\b'+gender.toLowerCase()+'\\b', 'g')
      return voice.name.toLowerCase().match(regex)
    })
  }

  if(!voiceList.length && lang === 'en-US'){
    voiceList = matchingLangList.filter(voice => voice.default)
  }

  if(voiceList.length){
    return {
      rate: rate,
      voice: voiceList[0],
      lang: lang,
      pitch: pitch || 1,
      //volume: volume || -1,
    }
  }
  else {
    voiceList = matchingLangList.filter(voice => voice.default)
    if(voiceList.length){
      return {
        rate: rate,
        voice: voiceList[0],
        lang: lang,
        pitch: pitch || 1,
        //volume: volume || -1
      }
    }
  }
}

/**
 * get list of voices 
 * @returns {Array} list of SpeechSynthesis objects
 */
export function getVoiceList(){
  if(!SYNTHTHESIS){
    throw new Error('SpeechSynthesis not available.')
  }
  return SYNTHTHESIS.getVoices()
}

/**
 * update recognition object properties
 * @param {Object} options.field object property
 * @param {Object} options.value property value
 */
export function setRecognitionField(update){
  if(Array.isArray(update)){
    if(!update.length){
      return
    }
    update.forEach(entry => {
      const { field, value } = entry
      RECOGNITION[field] = value
    })
  }
  else {
    const { field, value } = update
    RECOGNITION[field] = value
  }
}


/**
 * update speech-ulterance object properties
 * @param {Object} options.field object property
 * @param {Object} options.value property value
 */
 export function setUlteranceField(update){
  if(Array.isArray(update)){
    if(!update.length){
      return
    }
    update.forEach(entry => {
      const { field, value } = entry
      SPEECH_ULTERANCE[field] = value
    })
  }
  else {
    const { field, value } = update
    SPEECH_ULTERANCE[field] = value
  }
}

/**
 * speakTranslationText
 * @param {Object} options.text -ttext
 * @param {Object} options.response -response
 * @returns {Void} none
 */
export function speakTranslationText({ text, response }){
  try {
    const { targetLanguage } = parseParameters(response)
    const languageCode = deepL.supportedLanguages
    .find(lang => lang.name.toLowerCase() === targetLanguage.toLowerCase())
    if(!languageCode){
      throw new Error('Target Language not supported.')
    }

    const voice = getVoiceList()
    .find(voice => voice.lang.toLowerCase().includes(languageCode.language.toLowerCase()))

    // preserve the original language
    OLD_ULTERANCE.lang = SPEECH_ULTERANCE.lang
    OLD_ULTERANCE.voice = SPEECH_ULTERANCE.voice

    const textParts = text.split('<translate>').filter(part => {
      if(part.length) return part.trim()
    })

    const original = textParts[0], translate = textParts[1]

    IS_AGENT_SPEAKING = true
    speak(original) // speak the original text
    
    if(voice){
      SPEECH_ULTERANCE.lang = voice.lang
      SPEECH_ULTERANCE.voice = voice
      TEXT_TO_TRANSLATE = translate
      checkSpeakingStatusThenSpeak() 
    } 
    else {
      checkSpeakingStatusThenSpeak() 
    } 
  } catch (error) {
    ToasterService.error({
      message: error.message,
      position: 'top'
    })
  }
}


/**
 * parse parameters
 * @param {Object} options.parameters -response parameters
 * @returns {Object} parsed parameters
 */
 function parseParameters({ parameters }) {
  if (!parameters) {
    return {}
  }
  
  const params = {}
  const { fields } = parameters
  const properties = Object.getOwnPropertyNames(fields)

  if (properties && properties.length) {
    properties.forEach((prop) => {
      params[prop] = fields[prop].stringValue
    })
  }
  return params || {}
}

/**
 * checkSpeakingStatusThenSpeak
 */
function checkSpeakingStatusThenSpeak() {
  if(IS_AGENT_SPEAKING) {
    window.setTimeout(checkSpeakingStatusThenSpeak, 100)
  } else {
    speak(TEXT_TO_TRANSLATE) // speak the translation
    SPEECH_ULTERANCE.lang = OLD_ULTERANCE.lang
    SPEECH_ULTERANCE.voice = OLD_ULTERANCE.voice
  }
}

// ******************************** EVENT LISTERS ********************************

RECOGNITION.onresult = async (recording) => {
  const text = recording.results[0][0].transcript
  await store.dispatch('speechRecognition/invoke', text, { root: true })
  stopRecognition()
}

RECOGNITION.onstart = (recording) => {
  store.dispatch('speechRecognition/setIsRecognizing', true, { root: true })
}

RECOGNITION.onend = (recording) => {
  store.dispatch('speechRecognition/setIsRecognizing', false, { root: true })
}


SPEECH_ULTERANCE.onend = (event) => {
  try{
    IS_AGENT_SPEAKING = false
    store.dispatch('speechRecognition/setIsSpeaking', false)
  }
  catch(err){
    console.error('Failed to update speaking status', err)
  }
}

SPEECH_ULTERANCE.onstart = (event) => {
  try{
    IS_AGENT_SPEAKING = true
    store.dispatch('speechRecognition/setIsSpeaking', true)
  }
  catch(err){
    console.error('Failed to update speaking status', err)
  }
}
