// Written By : Nemese Kalubi
// Date: Fri Apr 15, 2022

/**
 * @module socket.event.types
 */

export default {
  MESSAGE_SENT: 'MESSAGE_SENT',
  FETCH_REPORTS: 'FETCH_REPORTS',
  FETCH_TEMPERATURE: 'FETCH_TEMPERATURE',
  AGENT_DISPLAY_CHAT: 'AGENT_DISPLAY_CHAT',
  DISPLAY_ROUTE_INFO: 'DISPLAY_ROUTE_INFO',
  NEW_MESSAGE_RECEIVED: 'NEW_MESSAGE_RECEIVED',
  UPDATE_CHART_SETTINGS: 'UPDATE_CHART_SETTINGS',
}