import { alertController } from "@ionic/vue";

export default {
  presentAlert: async ({ options, fn } = {}) => {
    const alert = await alertController.create({
      header: "Alert",
      subHeader: "Subtitle",
      message: "This is an alert message.",
      buttons: ["OK"],
    });
    await alert.present();
  },
  presentAlertConfirm: async ({ message, callback, header }) => {
    const alert = await alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel:");
          },
        },
        {
          text: "Okay",
          handler: () => {
            callback();
          },
        },
      ],
    });
    return alert.present();
  },
};
