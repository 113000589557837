// Written By : Nemese Kalubi
// Date: Sun, Sep 11, 2022

import axios from 'axios'

/**
 * @module metrics.service
 * this file contains the metrics feature
 */

export default {
  getTemperature() {
    return axios.get('/webhooks/ai/metrics/temperature')
  },
  getTopServices() {
    return axios.post('/webhooks/ai/metrics/top-services')
  },
  getServicesUsage() {
    return axios.get('/webhooks/ai/metrics/services-usage')
  }
}