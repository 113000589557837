// Written By : Nemese Kalubi
// Date: Fri Sept 23, 2022

import moment from 'moment'
import AvatarService from '../../services/avatar.service'
import ActivityService from "../../services/activity.service"

export default {
  namespaced: true,
  state: {
    activityLogs: [],
    logsTimeline: {},
  },
  getters: {
    selectActivities(state){
      return state.activityLogs
    },
    selectLogsTimeline(state){
      return state.logsTimeline
    }
  },
  mutations: {
    SET_ACTIVITY_LOGS(state, activities){
      state.activityLogs = activities
    },
    SET_LOGS_TIMELINE(state, timelines){
      state.logsTimeline = timelines
    }
  },
  actions: {
    async fetchUserAvatar({ commit }, userId){
      if(!userId){
        return ''
      }
      const response = await AvatarService.get(userId)
      return response.data.link
    },
    async fetchActivityLogs({ rootGetters, commit, dispatch }, state){
      const { _id: userId } = rootGetters['user/selectCurrentUser']
      const response = await ActivityService.getLogs({ userId })

      if(response && response.data){
        commit('SET_ACTIVITY_LOGS', response.data.logs)
        dispatch('generateLogsTimeline', response.data.logs)
      }
    },

    async searchActivityLogs({ commit, rootGetters, dispatch }, query){
      const { _id: userId } = rootGetters['user/selectCurrentUser']
      query.user = userId

      const response = await ActivityService.search({ ...query })

      if(response && response.data){
        commit('SET_ACTIVITY_LOGS', response.data.logs)
        dispatch('generateLogsTimeline', response.data.logs)
      }
    },

    generateLogsTimeline({ commit }, logsData){
      if(!logsData || !logsData.length){
        commit('SET_LOGS_TIMELINE', [])
      }

      if(!Array.isArray(logsData)){
        throw new Error('Data must be of array type')
      }

      const sortedTimeline = {}

      logsData.forEach(log => {
        const timestamp = moment(log.createdAt).format('LL')
        if(!sortedTimeline[timestamp]){
          sortedTimeline[timestamp] = {
            logs: [log]
          }
        }
        else{
          sortedTimeline[timestamp] = {
            logs: [...sortedTimeline[timestamp].logs, log]
          }
        }
      })
      commit('SET_LOGS_TIMELINE', sortedTimeline)
    }
  }
}