// Written By : Nemese Kalubi
// Date: Thu, March 24, 2022
import axios from "axios"
import config from "../src/config"
import StorageUtil from "./storage-util"

/**
 * @module axios.interceptor
 */

/**
 * Request interceptor
 * @param {Object} request object
 * @return {Object} request object
 */
const Interceptor = (() => {
  axios.interceptors.request.use(
    (request) => {
      const token = StorageUtil.read("token")
      // modify
      request = {
        ...request,
        headers: {
          ...request.headers,
          Authorization: token,
        },
        url: generateURL(request.url),
      }

      return request
    },
    (error) => {
      return Promise.reject(error)
    }
  )
})()

/**
 * generate url path
 * @param {String} path 
 */
const generateURL = (path) => {
  const regex = new RegExp(/^\/\b(webhooks)\//ig)
  const isWebhooks = regex.test(path)
  return isWebhooks ? config.hyacinth(path) : config.ozunu(path)
}

export default Interceptor
