/**
 * User service implementation
 */
import axios from "axios";

export default {
  // hyacinth ai service 
  create(payload) {
    return axios.post('/ai/hyacinth', {
      ...payload
    })
  },
  list(payload) {
    return axios.post('/ai/hyacinth/list', {
      ...payload
    })
  },
  update(payload) {
    return axios.put(`/ai/hyacinth/${payload.id}`, payload)
  },
};